import React from 'react';

interface ButtonProps {
  buttonText: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const checkButton: React.FC<ButtonProps> = ({ buttonText, onClick,style }) => {
  return (
    <button onClick={onClick} 
            className='lg:w-[80px] w-[60px] h-[30px] border border-[#0085FF] rounded-md text-[#0085FF] text-[13px] hover:bg-blue-600 hover:text-white font-medium '
            style={style}
    >
      		{buttonText}
    </button>
  );
}

export default checkButton;