
import Visual from './visual/VisualComponent';
import AI from './AI/aiComponent';
import Footer from '../../common/footer';



const Main = () => {
  return (
    <>
      <Visual/>
      <AI/>
      <Footer/>
    </>
  );
};

export default Main;