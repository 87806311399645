import {useState,useEffect} from 'react';
import {  useNavigate, useLocation } from 'react-router-dom';
import Button1 from '../button/bgButton';
import Button2 from '../button/bgNoneButton';
import {  fetchAdminUserList, fetchAdminModifyUser } from '../../lib/api/adminApi'
import Modal from '../modal/modalComponent';

interface modalProps {
	
	USER_ID:string;
    setIsModalOpen: () => void;
   
}
interface Inputs {
    

password:string;


}

interface ListItem {
	
	USER_ID: string;
	USER_NM: string;
	USER_RANK: string;
	HP_NO: string;
	USER_PW: string;

}




const ModalComponent = ({ setIsModalOpen,USER_ID } : modalProps)  => {

	const [ userList, setUserList ] = useState<ListItem[]>([]);
	const [ ShowModal, setShowModal] = useState(false);
	const [ password, setPassword ] = useState('');
	const [ reconfirmPassword, setReconfirmPassword ] = useState('');
	const [ passwordMismatch, setPasswordMismatch ] = useState(false);
	/* console.log('user_id:'+USER_ID) */
	const getUserList = async () => {

		
		const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
		// const user_Id: string = (getLoginInfo && JSON.parse(getLoginInfo)?.id);
		

	try {
		
		const inputData= {

				gbn:'SEL', 
				data1:USER_ID,
				data2:'',
				data3:'',
				data4:'',
				data5:'',
				

			}

			console.log('save input:'+ JSON.stringify(inputData));
			

			
		const {data} = await fetchAdminUserList(inputData)
	
		console.log('User_modi:'+ JSON.stringify(data))

		if(data.success){
		
		setUserList(data.data);
		localStorage.setItem('setUserList', JSON.stringify(data));
		// window.location.href = "/"

		}else{
		// alert('실패')

		}
	
	} catch (e) {
		// setError(e);
	}

	}

	useEffect(()=>{

	
	getUserList()
    },[])

	const [inputs, setInputs] = useState<Inputs>({

		password:'',


    })

	const handlePasswordChange = (e : any) => {
		setPassword(e.target.value);
		setPasswordMismatch(false); 
	}
	
	const handleReconfirmPasswordChange = (e : any) => {
		setReconfirmPassword(e.target.value);
		setPasswordMismatch(false); // 
	}


	const onChangeInfo = (e:any) => {
		//input에 name을 가진 요소의 value에 이벤트를 걸었다
		
		const { name, value } = e.target   
		const mkNm = 'chg';
		// 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
		const nextInputs = {            
			//스프레드 문법으로 기존의 객체를 복사한다.
			...inputs,  
			[name]: value,
			[mkNm+name]: true
		}
		//만든 변수를 seInput으로 변경해준다.
		setInputs(nextInputs)       

	}

	const handleSave = async () => {




			const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
	
			const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
		  
		   
			try {
	
				if(password !== reconfirmPassword){
					setPasswordMismatch(true);
					setShowModal(false);
					return;
				}
				
				const inputData= {
	
					in_sts: 'U_PW',
					in_user_id:UserId,
					in_user_rank: userList[0].USER_RANK,
					in_user_nm:userList[0].USER_NM,
					in_user_pw:inputs.password,
					in_hp_no:userList[0].HP_NO,
					in_login_id:UserId
				   
	
				}
					console.log('save input:'+ JSON.stringify(inputData));
			   
	
				/* 	return false;  */
					const {data} = await fetchAdminModifyUser(inputData)
			
					console.log('onClickLogin end:'+ JSON.stringify(data))
						if(data.success){
							localStorage.removeItem('junction_login')
							localStorage.removeItem('user_id')
								window.location.href = "/admin-login/"
	
						}else{
							
							alert('실패')
						}
					
					} catch (e) {
						// setError(e);
				}
		}	

	





  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="absolute inset-0  bg-gray-500 opacity-75 h-auto  " />
        <div className="z-10 lg: bg-white rounded-lg shadow-xl p-4 lg:h-auto  font-bold lg:h-[50%] lg:w-[50%] py-10 px-10 w-[90%] ">
            <div className='border-b flex relative pb-2'> 직원정보
            <div className='absolute right-4 text-[20px] cursor-pointer'  onClick={() => setIsModalOpen()}>X</div>
            </div>
		
			
            <div className='mt-4'>
            <label className='text-sm text-gray-600 pl-1'>변경할 비밀번호</label>
                		<div className="mt-2">
							<input
								id="password"
								name="password"
								type="password"
								onChange={(e) => {
									onChangeInfo(e);
									handlePasswordChange(e); // handlePasswordChange 함수 호출
								}}
								placeholder='변경할 비밀번호를 입력해주세요'
								className="w-full  border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600 "
							/>
                		</div>
						
            </div>
            <div>
                		<div className="mt-4">
							<label className='text-sm text-gray-600 pl-1'>변경할 비밀번호 확인</label>
							<input
								id="passwordRC"
								name="passwordRC"
								type="password"
								placeholder='변경할 비밀번호를 다시 입력해주세요'
								onChange={(e) => {
									onChangeInfo(e);
									handleReconfirmPasswordChange(e); // handleReconfirmPasswordChange 함수 호출
								}}
								className="w-full border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600 "
							/>
							{passwordMismatch && (
								<p className="text-red-500 mt-2 ml-2">비밀번호가 일치하지 않습니다.</p>
							)}
                		</div>
						
              		</div>
               
               
                	
				
		
			
            <div className='flex justify-center mt-10    mb-2'> 
                <button className='bg-[#2999ff] text-[18px] text-white w-[180px] h-[40px] rounded-lg font-bold cursor-pointer hover:bg-blue-600' onClick={()=>{setShowModal(true)}}> 수정하기</button>

             
            </div>
			{ShowModal &&(
				<Modal
				setIsModalOpen={() => setShowModal(false)}
				header={'비밀번호 변경 '}
				sendMessage={'비밀번호를 변경 하시겠습니까?.'}
				storageMessage={'Yes버튼을 누르면 변경 됩니다.'}
				handleSave={() => handleSave()}
				/>
			)}
			
    </div>
</div>
  )
}

export default ModalComponent;