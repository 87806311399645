import { JSX, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FaSpinner } from 'react-icons/fa6';
import { Document, Page } from 'react-pdf';

const pdf =  require("../../pdf/service-guide.pdf");

const AgreementServicePage = (): JSX.Element => {
  const [numPages, setNumPages] = useState<number>(0);
  return (
        <>
          <div className="pt-48 lg:hidden flex w-full h-full justify-center items-center">
              <div>모바일 환경에서는 PDF 파일을 불러올 수 없습니다.</div>
          </div>
          <div className="pt-12 hidden lg:flex w-full h-full justify-center items-center">
              <ErrorBoundary
                fallbackRender={() => (
                  <div className='flex h-52 w-full items-center justify-center'>
                    <div>PDF 파일을 불러오는 중 오류가 발생했습니다.</div>
                  </div>
                )}
                onReset={() => Function}
              >
                <Document
                  className='flex w-full flex-col items-center justify-center'
                  file={pdf}
                  onLoadSuccess={({ numPages }) => {
                    console.log('Document loaded with ' + numPages + ' pages');
                    setNumPages(numPages);
                  }}
                  options={{ workerSrc: '/pdf.worker.js' } as any}
                  renderMode='canvas'
                  loading={
                    <div className='flex h-52 w-full items-center justify-center'>
                      <FaSpinner className='animate-spin w-6 h-6' />
                    </div>
                  }
                >
                  {Array.from(new Array(numPages), (_, index) => {
                    return (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={840}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        loading={<div />}
                      />
                    );
                  })}
                </Document>
              </ErrorBoundary>
            </div>
        </>
  );
};

export default AgreementServicePage;
