import {useState,useEffect} from 'react';
import {  useNavigate, useLocation } from 'react-router-dom';
import Button1 from '../button/bgButton';
import Button2 from '../button/bgNoneButton';
import {  fetchAdminUserList, fetchAdminModifyUser } from '../../lib/api/adminApi'
import Modal from '../modal/modalComponent';

interface modalProps {
	
	USER_ID:string;
    setIsModalOpen: () => void;
   
}
interface Inputs {
    
username:string;
userId:string;
rank:string;
phoneNumber:string;


}

interface ListItem {
	
	USER_ID: string;
	USER_NM: string;
	USER_RANK: string;
	HP_NO: string;
	USER_PW: string;

}




const ModalComponent = ({ setIsModalOpen,USER_ID } : modalProps)  => {

	const [ userList, setUserList ] = useState<ListItem[]>([]);
	const [ ShowModal, setShowModal] = useState(false);
	const [ ShowDel, setShowDel] = useState(false);
	const [ ShowReset, setShowReset] = useState(false);
	/* console.log('user_id:'+USER_ID) */
	const getUserList = async () => {

		
		const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
		// const user_Id: string = (getLoginInfo && JSON.parse(getLoginInfo)?.id);
		

	try {
		
		const inputData= {

				gbn:'SEL', 
				data1:USER_ID,
				data2:'',
				data3:'',
				data4:'',
				data5:'',
				

			}

			console.log('save input:'+ JSON.stringify(inputData));
			

			
		const {data} = await fetchAdminUserList(inputData)
	
		console.log('User_modi:'+ JSON.stringify(data))

		if(data.success){
		
		setUserList(data.data);
		localStorage.setItem('setUserList', JSON.stringify(data));
		// window.location.href = "/"

		}else{
		// alert('실패')

		}
	
	} catch (e) {
		// setError(e);
	}

	}

	useEffect(()=>{

	
	getUserList()
    },[])

	const [inputs, setInputs] = useState<Inputs>({

        username:'',
        userId:'',
        rank:'',
        phoneNumber:'',


    })


	const onChangeInfo = (e:any) => {
		//input에 name을 가진 요소의 value에 이벤트를 걸었다
		
		const { name, value } = e.target   
		const mkNm = 'chg';
		// 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
		const nextInputs = {            
			//스프레드 문법으로 기존의 객체를 복사한다.
			...inputs,  
			[name]: value,
			[mkNm+name]: true
		}
		//만든 변수를 seInput으로 변경해준다.
		setInputs(nextInputs)       

	}

	const handleSave = async () => {




			const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
	
			const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
		  
		   
			try {
	
				
				const inputData= {
	
					in_sts: 'U',
					in_user_id:inputs.userId ? inputs.userId :userList[0].USER_ID,
					in_user_rank:inputs.rank ? inputs.rank :userList[0].USER_RANK,
					in_user_nm:inputs.username ? inputs.username :userList[0].USER_NM,
					in_user_pw:'',
					in_hp_no:inputs.phoneNumber ? inputs.phoneNumber :userList[0].HP_NO,
					in_login_id:UserId
				   
	
				}
					console.log('save input:'+ JSON.stringify(inputData));
			   
	
			/* 		return false; */
					const {data} = await fetchAdminModifyUser(inputData)
			
					console.log('onClickLogin end:'+ JSON.stringify(data))
						if(data.success){
							setUserList(data.data);
							setIsModalOpen()
							window.location.href = "/admin-login/user"
							getUserList();
	
						}else{
							
							alert('실패')
						}
					
					} catch (e) {
						// setError(e);
				}
		}	

	const handleDelete = async () => {




		const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';

		const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
	  
	   
		try {

			
			const inputData= {

				in_sts: 'D',
				in_user_id:userList[0].USER_ID,
				in_user_rank:userList[0].USER_RANK,
				in_user_nm:userList[0].USER_NM,
				in_user_pw:'',
				in_hp_no:userList[0].HP_NO,
				in_login_id:UserId
			   

			}
				console.log('save input:'+ JSON.stringify(inputData));
		   

		/* 		return false; */
				const {data} = await fetchAdminModifyUser(inputData)
		
				console.log('onClickLogin end:'+ JSON.stringify(data))
					if(data.success){
						
						setUserList(data.data);
						setIsModalOpen()
						getUserList();
						window.location.href = "/admin-login/user"
					
						

					}else{
						
						alert('실패')
					}
				
				} catch (e) {
					// setError(e);
			}
	}	
	const handlePwreset = async () => {




		const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';

		const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
	  
	   
		try {

			
			const inputData= {

				in_sts: 'RESET_PW',
				in_user_id:userList[0].USER_ID,
				in_user_rank:userList[0].USER_RANK,
				in_user_nm:userList[0].USER_NM,
				in_user_pw:userList[0].USER_PW,
				in_hp_no:userList[0].HP_NO,
				in_login_id:UserId
			   

			}
				console.log('save input:'+ JSON.stringify(inputData));
		   

		/* 		return false; */
				const {data} = await fetchAdminModifyUser(inputData)
		
				console.log('onClickLogin end:'+ JSON.stringify(data))
					if(data.success){
						
					
						setUserList(data.data);
						getUserList();
						setIsModalOpen()
						window.location.href = "/admin-login/user"
						
						

					}else{
						
						alert('실패')
					}
				
				} catch (e) {
					// setError(e);
			}
	}	
	
	
	
	





  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="absolute inset-0  bg-gray-500 opacity-75 h-auto  " />
        <div className="z-10 lg: bg-white rounded-lg shadow-xl p-4 lg:h-auto  font-bold lg:h-[50%] lg:w-[50%] py-10 px-10 w-[90%] ">
            <div className='border-b flex relative pb-2'> 직원정보
            <div className='absolute right-4 text-[20px] cursor-pointer'  onClick={() => setIsModalOpen()}>X</div>
            </div>
		
			
            <div className='mt-4'>
            <label className='text-sm text-gray-600 pl-1'>사용자 이름</label>
                		<div className="mt-2">
							<input
								id="username"
								name="username"
								type="text"
								onChange={onChangeInfo}
								defaultValue={userList[0]?.USER_NM}
						
								className="w-full  border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600 "
							/>
                		</div>
						
            </div>
            <div>
                		<div className="mt-4">
							<label className='text-sm text-gray-600 pl-1'>아이디</label>
							<input
								id="userId"
								name="userId"
								type="text"
								placeholder='아이디'
								defaultValue={userList[0]?.USER_ID}
								disabled
							/* 	onChange={onChangeInfo} */
								className="w-full border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600 "
							/>
                		</div>
						
              		</div>
                 {/*      <div>
						<div className="mt-4">
							<label className='text-sm text-gray-600 pl-1'>비밀번호</label>
							<input
								id="password"
								name="password"
								type="text"
								placeholder='비밀번호'

                                
								
						
								className="lg:w-full  w-[200px]  sm:w-[300px] border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600   "
							/>
                		</div>
				
             		</div> */}
                     <div className="mt-4">
					 	<label className='text-sm text-gray-600 pl-1'>휴대폰번호</label>
							<input
								id="phoneNumber"
								name="phoneNumber"
								type="text"
								placeholder='휴대폰번호'
								defaultValue={userList[0]?.HP_NO}
								onChange={onChangeInfo}
								className="w-full  border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600  "
							/>
                		</div>
						<div className="mt-4">
					 	<label className='text-sm text-gray-600 pl-1'>직급</label>
							<input
								id="rank"
								name="rank"
								type="text"
								placeholder='직급'
								defaultValue={userList[0]?.USER_RANK}
                              
								onChange={onChangeInfo}
								className="w-full border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600  "
							/>
            		</div>
				
		
			
            <div className='flex justify-center mt-10    mb-2'> 
                <button className='bg-[#2999ff] text-[18px] text-white w-[180px] h-[40px] rounded-lg font-bold cursor-pointer hover:bg-blue-600' onClick={()=>{setShowModal(true)}}> 수정하기</button>
				<button className='border-[#2999ff] border lg:text-[18px] text-[#2999ff] w-[180px] h-[40px] rounded-lg font-bold cursor-pointer hover:bg-[#2999ff] hover:text-white ml-4'onClick={()=>{setShowReset(true)}}>PW초기화</button>
				<button className='border-red-500 border lg:text-[18px] text-red-500 w-[180px] h-[40px] rounded-lg font-bold cursor-pointer hover:bg-red-500 hover:text-white ml-4' onClick={()=>{setShowDel(true)}}>사용자 삭제</button>
             
            </div>
			{ShowModal &&(
				<Modal
				setIsModalOpen={() => setShowModal(false)}
				header={'수정'}
				sendMessage={'수정하시겠습니까?.'}
				storageMessage={'Yes버튼을 누르면 수정됩니다.'}
				handleSave={() => handleSave()}
				/>
			)}
			{ShowDel &&(
				<Modal
				setIsModalOpen={() => setShowDel(false)}
				header={'삭제'}
				sendMessage={'삭제하시겠습니까?.'}
				storageMessage={'Yes버튼을 누르면 삭제됩니다.'}
				handleSave={() => handleDelete()}
				/>
			)}
			{ShowReset &&(
				<Modal
				setIsModalOpen={() => setShowReset(false)}
				header={'초기화'}
				sendMessage={'비밀번호를 초기화 하시겠습니까?.'}
				storageMessage={'Yes버튼을 누르면 초기화됩니다.'}
				handleSave={() => handlePwreset()}
				/>
			)}
    </div>
</div>
  )
}

export default ModalComponent;