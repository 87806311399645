import { configureStore, createSlice } from '@reduxjs/toolkit'

let user = createSlice({
    name:'userNm',
    // initialState:'shin',
    initialState:{ name: '', email: ''},
    reducers:{
        changeNm(state, action){
            // return 'jongmin '+state
            console.log('action.payload:'+ action.payload)
            state.name = action.payload
        },
    }
})

export let { changeNm } = user.actions

export default configureStore({
    reducer: {
      user : user.reducer, 
    }
  }) 