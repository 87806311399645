import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../Img/blogo.png'
import { fetchAuthLogin} from '../lib/api/authApi' 
import { useLoginStore, initialLoginState } from '../store/main-store'

const Login = () => {

	// ! zustand store 모음
	const { setLogin: onLogin } = useLoginStore((state) => state);
	
	const [ inputId, setInputId ] = useState('')
	const [ inputPw, setInputPw ] = useState('')
	const [ showId, setShowId ] = useState<boolean>(false)
	const [ showPw, setShowPw ] = useState<boolean>(false)
	const [ errMsg, setErrMsg ] = useState<string>('')

	const handleInputId = (e: React.ChangeEvent<HTMLInputElement>) => {
		setShowId(false)
		setErrMsg('')
		setInputId(e.target.value)
	}

	const handleInputPw = (e: React.ChangeEvent<HTMLInputElement>) => {
		setShowPw(false)
		setErrMsg('')
		setInputPw(e.target.value)
	}
	const handleOnKeyPressId = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
		
		}
	  };
	const handleOnKeyPressPw = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
		  onClickLogin(); 
		}
	};

 
	const onClickLogin = async () => {
		try {
			if(inputId == ''){
				setErrMsg('아이디를 확인해주세요')
				setShowId(true)
				return false;
			}
			if(inputPw == ''){
				// setInputId('')
				setErrMsg('비밀번호를 확인해주세요')
				setShowPw(true)
				return false;
			}

			
			const inputData = {
				in_gbn: 'LOGIN',
				in_user_id:inputId,
				in_user_pw:inputPw
			}

			console.log('onClickLogin start:'+ JSON.stringify(inputData))
			const {data} = await fetchAuthLogin(inputData)
	
			console.log('onClickLogin end:'+ JSON.stringify(data))
			if(data.success){
			
				onLogin({
					token: data.token,
					USER_ID: data.USER_ID,
					USER_NM: data.USER_NM,
					USER_RANK: data.USER_RANK,
					HP_NO: data.HP_NO,
					imageUrl: '',
					isFetching: false,
					error: false,
				});

				// localStorage.setItem('junction_login', JSON.stringify(data));
				window.location.href = "/"
			}else{
				onLogin({
					...initialLoginState,
					error: true,
				});

				if(data.msgGbn === "user_id"){
					// setInputId('')
					setErrMsg(data.msg)
					setShowId(true)

				}else{
					// setInputPw('')
					setErrMsg(data.msg)
					setShowPw(true)
				}

			}
		
		} catch (e) {
			// setError(e);
		}
		}	
    return (
      <>
      
        <div className="flex items-center min-h-full flex-1 flex-col justify-center  h-[1028px] lg:px-8 ">
          	<div className="sm:mx-auto sm:w-full sm:max-w-sm">
            	<img
					className="mx-auto h-10 w-auto"
					src={logo}
					alt="Your Company"
      
            	/>
          	</div>
         	<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            	<div className="space-y-6 ">
            		<div>
                		<div className="mt-2">
							<input
								id="inputId"
								name="userId"
								type="text"
								value={inputId}
								onChange={handleInputId}
								placeholder='아이디'
								required
								onKeyPress={handleOnKeyPressId} 
								className="lg:w-full  w-[200px]  sm:w-[300px] border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600   "
							/>
                		</div>
						{showId &&(<p className="text-red-500 mt-1 ml-2 text-sm">{errMsg}</p>)}
              		</div>
              		<div>
						<div className="mt-2">
							<input
								id="inputPw"
								name="password"
								type="password"
								placeholder='비밀번호'
								value={inputPw}
								required
								onKeyPress={handleOnKeyPressPw} 
								onChange={handleInputPw}
								className="lg:w-full  w-[200px] sm:w-[300px]  border h-[40px] pl-4  rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600 "
							/>
                		</div>
						{showPw &&(<p className="text-red-500 mt-1 ml-2 text-sm">{errMsg}</p>)}
             		</div>
              		{/* <div className=''>
                		<p className='text-gray-400 text-sm pl-2 hover:font-bold cursor-pointer'>비밀번호 찾기</p>
              		</div> */}
             		<div className='pt-8'> 
						<button
							type="submit"
							className="flex lg:w-full sm:w-[300px]  w-[200px] h-[40px] justify-center items-center rounded-md bg-[#0085FF] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							onClick={onClickLogin}
						>
						로그인
						</button>
					</div>
            	</div>
          	</div>
        </div>
      </>
    )
  }

  export default Login