import  React from 'react';
import BG from '../../Img/adminBG.png';

export default function AdminMain() {
    
    return (
      <div className="flex w-full h-full  ">
        <div className=' w-full '>
            <div>
              <div className=" w-full">
                <img src={BG} className='w-full h-full '/>
                <h4 className="text-lg font-bold flex justify-center mt-20 lg:text-[50px] absolute lg:top-[50%] lg:left-[40%] text-gray-400 top-[10%] left-[40%]">정션메드 관리자 </h4>
              </div>
            </div>
        </div>
      </div>
    )
  }