import React from 'react';
import { useNavigate } from 'react-router-dom';

import ContactBg from '../../Img/contactBG.png';



import { FaAngleRight,
        FaPencil,FaRegFile,
        FaMagnifyingGlass,
        FaRegEnvelope       
        } 
from "react-icons/fa6";

const ContactMainPage = () => {
    const navigate = useNavigate();

    const handleButtonClick = (): void => {
        navigate('/contactDetail');
    }

    return (
        <>
        <div className=' pt-[140px]'>
            <div className=''>
                <div className='flex justify-center lg:text-[40px] text-[16px] font-bold'>정션메드의 서비스와 기술을</div>
                <div className='flex justify-center lg:text-[40px] text-[16px] font-bold'>함께할 수 있는 다양한 제안을 기다립니다.</div>
            </div>
            <div className='lg:pt-[60px] lg:pb-[50px] pt-10 pb-10 lg:ml-0 ml-10 lg:mr-0 mr-10'>
                <div className='flex justify-center lg:text-[18px] text-[14px]'>정션메드는 빅데이터 기술과 의료, 기술, 문화 등 다양한 분야의 파트너들과의 연계로</div>
                <div className='flex justify-center lg:text-[18px] text-[14px]'>가족과 전문가가 함께, 더 쉽게 건강을 관리하기 위한 기술과 플랫폼을 제공합니다.</div>
            </div>
        </div>
        <div className='relative flex justify-center items-center '>
         <img src={ContactBg} className=' w-full '/>
        {/*  <div className='text-[98px] font-extrabold absolute text-[#B0E4FF]'>Junction<strong>Med</strong></div> */}
        </div>
        <div className='mt-[100px] mb-[100px]'> 
            <div className='flex justify-center'>
                <button className='bg-[#2999ff] text-[20px] text-white w-[250px] h-[50px] rounded-lg font-bold cursor-pointer' onClick={handleButtonClick}>정션메드에 제안하기 </button>
            </div>
        </div>
        <div className=''>
            <div className=''>
                 <div className='border-b-2 w-full border-gray-100 mb-[60px] '></div>
                 <div className='lg:mt-[100px] lg:text-[30px] text-[20px] font-bold lg:ml-[25%] ml-[10%] mb-10'>
                    제안 가이드
                 </div>
                 <div className='flex justify-center mb-20 lg:ml-0 ml-4 lg:mr-0 mr-4'>
                    <div className='lg:pr-10 pr-2'>
                        <div className='lg:w-[120px] lg:h-[120px] w-[40px] h-[40px] rounded-full bg-gray-100 flex justify-center items-center '>
                            <div className='flex justify-center items-center '>
                                <FaPencil className='lg:text-[30px] '/>    
                            </div>
                            
                        </div>
                        
                        <div className='flex justify-center pt-6'>
                            <div className='font-bold lg:text-[16px] text-[10px]'>문의제안 등록</div>
                        </div>
                    </div>
                    <div className='lg:mt-10 lg:mr-10 mt-4 mr-2'>
                        <FaAngleRight className='lg:text-[30px] text-gray-500' />
                    </div> 
                        
                  
                    <div className='lg:pr-10 pr-2'>
                       
                        <div className='lg:w-[120px] lg:h-[120px] w-[40px] h-[40px] rounded-full bg-gray-100 flex justify-center items-center'>
                        <div className='flex justify-center items-center '>
                                <FaRegFile className='lg:text-[30px]  '/>    
                            </div>
                        </div>
                        <div className='flex justify-center pt-6'>
                            <div className='lg:text-[16px] text-[10px] font-bold '>내용접수</div>
                        </div>
                    </div>
                    <div className='lg:mt-10 lg:mr-10 mt-4 mr-4 lg:ml-0 ml-2'>
                        <FaAngleRight className='lg:text-[30px]  text-gray-500' />
                    </div> 
                        
                    <div className='lg:pr-10 '>
    
                        <div className='lg:w-[120px] lg:h-[120px] w-[40px] h-[40px]  rounded-full bg-gray-100 flex justify-center items-center'>
                        <div className='flex justify-center items-center '>
                                <FaMagnifyingGlass className='lg:text-[30px] '/>    
                            </div>

                        </div>
                        <div className='lg:flex justify-center pt-6'>
                            <div className='lg:text-[16px] text-[10px] font-bold lg:w-auto w-[60%]'>담당자 확인/검토</div>
                        </div>
                    </div>
                    <div className='lg:mt-10 lg:mr-10 mt-4 mr-2'>
                        <FaAngleRight className='lg:text-[30px] text-gray-500' />
                    </div> 
                        
                    <div className='lg:pl-0 pl-2'>
                        <div className='lg:w-[120px] lg:h-[120px] w-[40px] h-[40px] rounded-full bg-gray-100 flex justify-center items-center'>
                        <div className='flex justify-center items-center '>
                                <FaRegEnvelope className='lg:text-[30px] '/>    
                            </div>

                        </div>
                        <div className='lg:flex justify-center pt-6 '>
                            <div className='lg:text-[16px] text-[10px] font-bold lg:w-auto w-[60%]'>이메일로 결과 회신</div>
                        </div>
                    </div>
               
                </div>
               
            </div>
           
        </div>
        
        </>
    );
};

export default ContactMainPage;