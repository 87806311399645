
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLoginStore } from './store/main-store';
import { isEmpty } from 'lodash-es';
import Header from './common/header';
import AdminHeader from './common/adminHeader';
import Router from './router'; 
import Main from './pages/mainPage/mainPage'
import Info from './pages/InfoPage/infoPage'
import Service from './pages/servicePage/servicePage'
import ServiceInfo from './pages/servicePage/serviceInfoPage'
import ServiceInfoEng from './pages/servicePage/serviceInfoEngPage'
import Position from './pages/compositionPage/positionPage'
import Partner from './pages/compositionPage/partnerPage'
import Media from './pages/mediaPage/mediaPage'
import MediaNews from './pages/mediaPage/newsPage'
import ContactMain from './pages/contactPage/contactMainPage'
import ContactDetail from './pages/contactPage/contactDetailPage'
import AdminUserPage from './pages/adminPage/adminUserPage';
// import RegisterPage from './pages/adminPage/registerPage';
import EditorBPage from './pages/adminPage/Editor/adminEditorBPage';
import EditorNPage from './pages/adminPage/Editor/adminEditorNPage';
import EditorBModifyPage from './pages/adminPage/Editor/adminEditorBModifyPage';
import EditorNModifyPage from './pages/adminPage/Editor/adminEditorNModifyPage';
import EditorBListPage from './pages/adminPage/Editor/adminEditorBList';
import EditorNListPage from './pages/adminPage/Editor/adminEditorNList';
import EditorBViewPage from './pages/adminPage/Editor/EditorBViewPage';
import EditorNViewPage from './pages/adminPage/Editor/EditorNViewPage';
import Login from './common/login';
import AdminMain from './pages/adminPage/adminMainPage';
import AgreementServicePage from './pages/agreementPage/AgreementServicePage';
import AgreementPrivacyPage from './pages/agreementPage/AgreementPrivacyPage';

import { pdfjs } from 'react-pdf';

// ! pdfjs 라이브러리를 사용하기 위한 설정
// - 서비스 이용약관, 개인정보 처리방침 페이지에서 pdf 파일을 불러오기 위한 설정
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const  App = () => {

    const { login } = useLoginStore((state) => state);

  // 로그인 상태에 따라 적절한 경로로 이동합니다.
    const redirectToLogin = () => {
        if (isEmpty(login?.token)) {
            return <Navigate to="/admin-login" replace />;
        }
    };

// 로그인되지 않은 경우
if (isEmpty(login?.token)) {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/admin-login" element={<Login />} />
                {/* 로그인하지 않은 사용자도 접근 가능한 페이지들 */}
                <Route path="/" element={<Main />} />
                <Route path="/info" element={<Info />} />
                <Route path="/service" element={<Service />} />
                <Route path="/serviceinfo" element={<ServiceInfo />} />
                <Route path="/en-serviceinfo" element={<ServiceInfoEng />} />
                <Route path="/position" element={<Position />} />
                <Route path="/partner" element={<Partner />} />
                <Route path="/media" element={<Media />} />
                <Route path="/mediaNews" element={<MediaNews />} />
                <Route path="/user-editorBView" element={<EditorBViewPage/>} />
                <Route path="/user-editorNView" element={<EditorNViewPage/>} />
                <Route path="/contactMain" element={<ContactMain />} />
                <Route path="/contactDetail" element={<ContactDetail />} />
                <Route path="/agreement-service" element={<AgreementServicePage />} />
                <Route path="/agreement-privacy" element={<AgreementPrivacyPage />} />
            </Routes>
            
        </>
    );
}

// 로그인된 경우
const logoutRouter = Router().logoutRouter;
return (
    <>
        <AdminHeader />
        <Routes>
           {/*  
            <Route path="/" element={<Main />} />
            <Route path="/info" element={<Info />} />
            <Route path="/service" element={<Service />} />
            <Route path="/serviceinfo" element={<ServiceInfo />} />
            <Route path="/position" element={<Position />} />
            <Route path="/partner" element={<Partner />} />
            <Route path="/media" element={<Media />} />
            <Route path="/contactMain" element={<ContactMain />} />
            <Route path="/contactDetail" element={<ContactDetail />} /> */}
            {/* 로그인이 필요한 페이지로 리다이렉트 */}
            <Route path="/admin-login" element={<Navigate to="/admin-login" />} />
            <Route path="/" element={<AdminMain />} />
            <Route path="/admin-login/user" element={<AdminUserPage/>} />

            <Route path="/admin-login/user-BEditor" element={<EditorBPage content="내용을 작성하세요" />} />
            <Route path="/admin-login/user-NEditor" element={<EditorNPage content="내용을 작성하세요" />} />
            <Route path="/admin-login/user-editorBList" element={<EditorBListPage/>} />
            <Route path="/admin-login/user-editorNList" element={<EditorNListPage/>} />
            <Route path="/admin-login/user-BModifyEditor" element={<EditorBModifyPage/>} />
            <Route path="/admin-login/user-NModifyEditor" element={<EditorNModifyPage/>} />
       

        </Routes>
      
    </>
);
};

export default App;