import React, { useRef} from 'react';
// import { useSession } from 'next-auth/react';
import { useEditor, EditorContent, FloatingMenu, BubbleMenu,Editor } from '@tiptap/react'
import { fetchImgPath } from '../../../lib/api/commApi';
// import InfoInputFile from '../../common/input/InfoInputFile';
import EditorInputFile from '../../../common/input/EditorInputFile';
import { useForm } from 'react-hook-form';


type TForm = {
    boardFile: FileList[];
  };


function AddPhoto({ editor }: { editor: Editor }) {


    const { watch, register, setValue} = useForm<TForm>({
        mode: 'onChange',
        defaultValues: {
            boardFile: [],
            // files:[],
          },
          values: {
            boardFile: [],
          },          
    });
    const inputFileRef1 = useRef<HTMLInputElement>(null);
    const handleUploadPhoto = async ()  => {
       
      console.log('1')
        let mkfiles: FileList[] = [];

        if (Array.isArray(watch('boardFile')) && watch('boardFile').length > 0) {
            mkfiles.push(watch('boardFile')[0]);
        }

            const inputData = {

                files: mkfiles as FileList[],

                
            
            }

            console.log('save input:'+ JSON.stringify(inputData));

            const {data} = await fetchImgPath(inputData);     
        
            console.log('data:'+ JSON.stringify(data));

            const IMG_URL = `${data[0].path}`;
            console.log('IMG_URL:'+ IMG_URL);   
            editor.commands.setImage({ src: IMG_URL });
            setValue('boardFile', []);
            if (inputFileRef1 && inputFileRef1.current) {
              inputFileRef1.current.value = ''; // 빈 문자열로 설정하여 초기화
            }
         
    };
  return (
    <button
      type="button"
      className="relative w-8 h-8 cursor-pointer opacity-70 hover:opacity-40"
    >
        <EditorInputFile handleUploadPhoto={handleUploadPhoto} inputFileRef={inputFileRef1} watch={watch} setValue={setValue} isLast={true}  />

    </button>
  );
}

export default AddPhoto;