import React, { useState,useRef } from 'react';
import {  fetchSendMail } from '../../lib/api/commApi'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InfoInputFile from '../../common/input/InfoInputFile';
import ProposalInputFile from '../../common/input/ProposalInputFile';
import Modal from '../../common/modal/modalComponent';
import WarnningModal from '../../common/modal/warningComponent';

interface Inputs {
    workname: string;
    homepage: string;
    business: string;
    name: string;
    position: string;
    phone: string;
    email: string;
    proposal: string;
    purpose: string;
    // Proposalfile: FileList[] | undefined;
    // Infofile: FileList[];
    // files: FileList[];
}
type TForm = {
    // files: FileList[];
    Proposalfile: FileList[];
    Infofile: FileList[];
  };
  
const ContactDetailPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    // const [file, setFile] = useState<File | null>(null);
    const navigate = useNavigate();
    const { watch, register, setValue} = useForm<TForm>({
        mode: 'onChange',
        defaultValues: {
            Infofile:[],
            Proposalfile: [],
            // files:[],
          },
          values: {
            Infofile:[],
            Proposalfile: [],
          },          
    });
    const inputFileRef1 = useRef<HTMLInputElement>(null);
    const inputFileRef2 = useRef<HTMLInputElement>(null);

    const [inputs, setInputs] = useState<Inputs>({
        workname: '',
        homepage: '',
        business: '',
        name: '',
        position: '',
        phone: '',
        email: '',
        proposal: '',
        purpose: '',
        // Infofile: watch('Infofile') as FileList[],
        // Proposalfile: watch('Proposalfile') as FileList[] | undefined,
    });

    const onChangeInfo = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        const nextInputs = {
            ...inputs,
            [name]: value,
        };
        setInputs(nextInputs);
    };

    const handleSave = async () => {

        try {
        
            const formData = new FormData();
            const getLoginInfo = localStorage.getItem('junction_login');
         /*    const UserId = JSON.parse(getLoginInfo)?.user_id; */
       
            // let files = [] as FileList[];
            // watch('Proposalfile'), watch('Infofile')에서 파일정보를 두개가 다 있음 다 넣고 아니면 한개만 files에 append 해준다.
            

            let mkfiles: FileList[] = [];

     /*        console.log('watch(Infofile):'+ JSON.stringify(watch('Infofile')));
            console.log('watch(Proposalfile):'+ watch('Proposalfile')); */

            // watch(Infofile) 이 undefined이거나 아무값이 없을때는 실행하지 않는다.
            
            if (Array.isArray(watch('Proposalfile')) && watch('Proposalfile').length > 0) {
                mkfiles.push(watch('Proposalfile')[0]);
            }
            if (Array.isArray(watch('Infofile')) && watch('Infofile').length > 0) {
                mkfiles.push(watch('Infofile')[0]);
            }
            if(inputs.workname == ''){
                setShowWarning(true);
                return false;
            }
            if(inputs.name == ''){
                setShowWarning(true);
                return false;
            }
            if(inputs.phone == ''){
                setShowWarning(true);
                return false;
            }
            if(inputs.email == ''){
                setShowWarning(true);
                return false;
            }
            if(inputs.proposal == ''){
                setShowWarning(true);
                return false;
            }

         /*    console.log('mkfiles:'+ JSON.stringify(mkfiles)); */
            const inputData = {

                
                workname:inputs.workname,
                homepage:inputs.homepage,
                business:inputs.business,
                name:inputs.name,
                position:inputs.position,
                phone:inputs.phone,
                email:inputs.email,
                proposal:inputs.proposal,
                purpose:inputs.purpose,
                files: mkfiles as FileList[],

              
          
            }
       /*      console.log('save input:'+ JSON.stringify(inputData)); */
            // return;
       /*       console.log('save input:'+ JSON.stringify(inputData));
            return false */
            const {data} = await fetchSendMail(inputData); 
         /*    console.log('getHrOne end:'+ JSON.stringify(data))  */
            if('메일전송완료'){

                setShowModal(false);
                window.location.href = "/"
        
            }else{
          
             
            }
            
        } catch (e) {
            
        }		

	
};
    const showModalClick = () => {
        setShowModal(true);
    }



    return (
        <>
            <div className='mb-[200px]'>
                <div className='flex justify-center'>
                    <div className='w-[70%] mt-[200px]'>
                        <div>
                            <h1 className='text-[36px] text-gray-700'>정션메드</h1>
                            <h1 className='text-[36px] font-extrabold text-gray-700'>제휴 제안</h1>
                        </div>
                        <div className='mt-[80px] h-[70px] border-b'>
                            <h1 className='text-[22px] text-gray-700 font-extrabold'>기업(담당자) 및 제안 정보 </h1>
                        </div>
                        <div className='pt-10'>
                            <label className='block'>
                                <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                    회사(단체) 명
                                </span>
                            </label>
                            <input
                                type="text"
                                className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px] focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                                id='workname'
                                name='workname'
                                onChange={onChangeInfo}
                            />
                        </div>
                        <div className='pt-4'>
                            <label className='block'>
                                <span className=" after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                    홈페이지
                                </span>
                            </label>
                            <input
                                type="text"
                                className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px] focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                                id='homepage'
                                name='homepage'
                                onChange={onChangeInfo}
                            />
                        </div>
                        <div className='pt-4'>
                            <label className='block'>
                                <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                    업종
                                </span>
                            </label>
                            <input
                                type="text"
                                className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px] focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                                id='business'
                                name='business'
                                onChange={onChangeInfo}
                            />
                        </div>
                        <div className='pt-4'>
                            <label className='block'>
                                <span className=" after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                    회사소개서
                                </span>
                            </label>
                            <div className=' mt-4'>
                            <InfoInputFile inputFileRef={inputFileRef1} watch={watch} setValue={setValue} isLast={true} />
                           
                        </div>
                    </div>
                    <div className='pt-4'>
                        <label className='block'>
                            <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                담당자 이름
                            </span>
                        </label>
                        <input type="text" className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px]   focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                             id='name'
                             name='name'
                             onChange={onChangeInfo}  />
                    </div>
                   
                    <div className='pt-4'>
                        <label className='block'>
                            <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                직급/직책
                            </span>
                        </label>
                        <input type="text" className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px]   focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                         id='position'
                         name='position'
                         onChange={onChangeInfo} />
                    </div>
                    <div className='pt-4'>
                        <label className='block'>
                            <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                전화번호
                            </span>
                        </label>
                        <input type="text" className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px]   focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                           id='phone'
                           name='phone' 
                           onChange={onChangeInfo} />
                    </div>
                    <div className='pt-4'>
                        <label className='block'>
                            <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                이메일 주소
                            </span>
                        </label>
                        <input type="text" className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px]   focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                            id='email'
                            name='email'
                            onChange={onChangeInfo}  />
                    </div>
                    <div className='pt-4'>
                        <label className='block'>
                            <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                제휴 제안 내용
                            </span>
                        </label>
                        <input type="text" className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px]   focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                                id='proposal'
                                name='proposal' 
                                onChange={onChangeInfo} />
                    </div>
                    <div className='pt-4'>
                        <label className='block'>
                            <span className=" after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                제휴 목적 및 기대효과
                            </span>
                        </label>
                        <input type="text" className='w-[100%] h-[34px] mt-1 border border-gray-200 pl-[6px] py-[12px]   focus:outline-none focus:border-none focus:ring-1 focus:ring-gray-500'
                            id='purpose'
                            name='purpose' 
                            onChange={onChangeInfo} />
                    </div>
                    <div className='pt-4'>
                        <label className='block'>
                            <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                제안서 첨부
                            </span>
                        </label>
                        <div className='mt-4'>
                            <ProposalInputFile inputFileRef={inputFileRef2} watch={watch} setValue={setValue} isLast={true} />
                        </div>
                      
                    </div>
                    <div className='pt-4'>
                        <label className='block'>
                            <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-[14px] font-medium text-slate-700">
                                개인정보 수집 및 이용동의 
                            </span>
                        </label>
                        
                        <textarea  className='w-[100%] mt-1 border border-gray-200 pl-[6px] py-[12px]  focus:outline-none focus:border-none  text-sm pr-[6px] text-gray-500 h-[150px]'>
                                
                                정션메드(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다. 
                                
                                1. 목적 : 업무 제휴 제안 접수
                               
                                2. 항목 : 회사(기관)명, 이름, 직급/직책, 전화번호, 이메일주소, 제안내용
                               
                                3. 보유기간 : 등록일로부터 90일간 보관합니다. 단, 제휴 제안이 기각/거절/철회/파기될 경우 수집된 정보를 즉시 파기합니다.

                                위 정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부 시에는 제휴 제안 접수가 제한될 수 있습니다.

                                *유의사항

                                정션메드는 제안 사항에 관한 검토 진행 상황이나 결과에 대해 별도의 회신을 드리지 않습니다. 제안자가 자신이 제안한 사항의 폐기를 원하는 경우, 제안자는 이를 정션메드에 요청할 수 있으며 정션메드는 요청 접수 후 확인하는 대로 즉시 폐기합니다.

                                단, 검토가 진행되기 전 '등록 상태'인 경우에 한하여 철회가 가능하며, 제안 검토가 진행 중인 경우 즉각적인 폐기는 어려울 수 있습니다.

                                정션메드는 제안 사항에 대해 별도의 비밀유지협약(NDA)을 체결하지 않으며, 제안자의 영업 기밀에 대한 보안 의무를 지지 않습니다. 따라서 제안하는 내용에 제안자의 영업 활동과 관련된 기밀 정보가 포함되지 않도록 주의해 주시기를 바랍니다.

                                정션메드는 제안 사항을 성실히 검토하여 사업성이 있다고 판단하는 경우, 제안자와 협의를 통해 제휴 계약 체결 등 별도의 사업 절차를 진행하게 됩니다. 다만, 제안자가 제안한 내용은 이미 정션메드가 내부적으로 진행 중인 사업과 동일 또는 유사할 수 있으며, 제 3자로부터 먼저 제안 받은 내용일 수 있습니다. 따라서 정션메드의 제안 사항에 대한 검토 진행이 제안자와의 제휴 사업 진행을 약속 드리는 것은 아니며, 제안 내용의 신규성 혹은 독창성이나 제안 내용에 대한 제안자의 우선권을 인정하는 것은 아님을 말씀드립니다.

                                정션메드와의 사업 제휴와 무관하거나 담당자와의 면담 또는 사업 진행을 전제로 한 제안일 경우 접수가 반려될 수 있음을 양해 바랍니다.
                        </textarea>
                    </div>
                    <div className='mt-2'>
                     
                    <input type='checkbox'className='mr-2 w-4 h-4 '
                     />
                        개인정보 수집 및 이용약관에 동의합니다
                    </div>
                    {showModal && (
                        <Modal
                            header='문의등록'
                            sendMessage='문의를 등록하시겠습니까?.'
                            storageMessage='Yes 버튼을 누르시면 문의가 등록됩니댜.'
                            setIsModalOpen={() => setShowModal(false)}
                            handleSave={() => handleSave()}
                        />
                        )
                    }
                    {showWarning && (
                        <WarnningModal
                            header='필수값 확인 '
                            sendMessage='필수항목을 입력하지 않았습니다.'
                            storageMessage='필수 입력항목을 확인해주세요.'
                            setIsModalOpen={() => setShowWarning(false)}
                        />
                    )}
                   
                    <div className='flex justify-center mt-20'>
                        <button className='bg-[#2999ff] text-[14px] text-white w-[150px] h-[37px] rounded-lg font-bold cursor-pointer' onClick={showModalClick}>문의 등록하기</button>
                    </div>
                   
                </div>

            </div>
        </div>
 
        
        </>
    );
};

export default ContactDetailPage;