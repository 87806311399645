import { Fragment, useState,useEffect } from 'react'
import{ useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon, } from '@heroicons/react/20/solid'
import { fetchAdminBoardList,fetchAdminBoardModify } from '../../../lib/api/adminApi'
import Modal from '../../../common/modal/modalComponent';

interface ListItem {
    TITLE: string;
    WRITE_DT: string;
    WRITE_SEQ: number;
    NOTICE_YN: string;
    TITLE_IMG_PATH: string;
    START_DT: string;
    END_DT: string;
    BOARD_GBN: string;
    SEQ: number | string;
    CONTENTS: string;
}


const statuses: { [key: string]: string } = {
    BOARD_GBN: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    
};


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const AdminBoardNewsListPage = () => {
    const navigate = useNavigate();
    const [ ShowDel, setShowDel] = useState(false); 
    const [ delId, setDelId ] = useState('')
    const [boardList, setBoardList] = useState<ListItem[]>([]);

    const getBoardList = async () => {

        const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
        const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
        

    try {
        
        const inputData= {

            in_gbn:'ALL', 
            in_seq:'',
            in_board_gbn:'뉴스',
            in_keyword:'',

                

            }

         console.log('save input:'+ JSON.stringify(inputData));
            

            
        const {data} = await fetchAdminBoardList(inputData)
    
        console.log('User:'+ JSON.stringify(data))

        if(data.success){
        
        setBoardList(data.data);
        localStorage.setItem('setBoardList', JSON.stringify(data));
        // window.location.href = "/"

        }else{
        // alert('실패')

        }
    
    } catch (e) {
        // setError(e);
    }

    }

    



    useEffect(() => {
        getBoardList();
    }, []);

    const GoEditor = () => {
        window.location.href = '/admin-login/user-NEditor'
    }
    const ModifyClick = (ModifyId:any,getContent:any) =>{
        navigate("/admin-login/user-NModifyEditor",{ state: {seq:ModifyId, contents:getContent }} )
    }


    const delClick = (getDelId:any) =>{
       
        setShowDel(true);
        setDelId(getDelId);
    

    }

    const BoardDelClick = async () => {
        

        const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
        const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
        
        try {
            
            const inputData= {
    
                in_sts: 'D',
                in_seq: delId,
                in_board_gbn: '뉴스',
                in_write_dt:'',
                in_write_seq:'',
                in_notice_yn:'',
                in_title_img_path:'',
                in_title:'',
                in_contents:'',
                in_start_dt:'',
                in_end_dt:'',
                in_id: UserId,
        
                    
        
                }
    
             console.log('save input:'+ JSON.stringify(inputData));
                
    
                
            const {data} = await fetchAdminBoardModify(inputData)
        
            console.log('User:'+ JSON.stringify(data))
    
            if(data.success){
            
            setBoardList(data.data);
            localStorage.setItem('setBoardList', JSON.stringify(data));
            window.location.href = "/admin-login/user-editorNList"
    
            }else{
            // alert('실패')
    
            }
        
        } catch (e) {
            // setError(e);
        }
    }
  return (
    <div className='lg:ml-24 lg:mr-24 '>
        <div className='lg:mt-[50px] mt-10'>
            <div className='flex justify-end'>
                <button className='block rounded-md bg-[#0085FF] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-60' onClick={GoEditor}>작성하기</button>
            </div>
            <div className='border-b-4 border-[#0085FF]'>
                <h1 className='lg:text-[28px] text-[16px] font-bold'>뉴스 관리</h1>
            </div>
        </div>
        {boardList.length > 0 && (
        <ul role="list" className="divide-y divide-gray-100 pl-2 pr-4">
        {boardList.map((list) => (
            <li  className="flex items-center justify-between gap-x-6 py-5">
            <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">{list.TITLE}</p>
                <p
                    className={classNames(
                    statuses[list.NOTICE_YN],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                    )}
                >
                   {list.BOARD_GBN}
                </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                     <time dateTime={list.WRITE_DT}>{list.WRITE_DT}</time>
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">{list.END_DT} </p>
                </div>
            </div>
            <div className="flex flex-none items-center gap-x-4 ">
              {/*   <a
                href={project.href}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                >
                View project<span className="sr-only">, {project.name}</span>
                </a> */}
                <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                        {({ active }) => (
                        <a
                            href="#"
                            className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                            onClick={() => ModifyClick(list.SEQ, list.CONTENTS)}
                        >
                            수정<span className="sr-only">, </span>
                        </a>
                        )}
                    </Menu.Item>
                   
                    <Menu.Item>
                        {({ active }) => (
                        <a
                            href="#"
                            className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                            onClick={() => delClick(list.SEQ)}
                        >
                            삭제<span className="sr-only">, ddd</span>
                        </a>
                        )}
                    </Menu.Item>
                    </Menu.Items>
                </Transition>
                </Menu>
            </div>
            </li>
        ))}
        </ul> 
        )}
        {ShowDel &&(
				<Modal
				setIsModalOpen={() => setShowDel(false)}
				header={'삭제'}
				sendMessage={'삭제하시겠습니까?.'}
				storageMessage={'Yes버튼을 누르면 삭제됩니다.'}
				handleSave={() => BoardDelClick()}
				/>
		)}
    </div>
  )
};

export default AdminBoardNewsListPage;