
import './common.css'
import { Link } from 'react-router-dom'; 

 const footer = () => {

    return (
        <>
        <footer id='t-footer' className='bg-[#6D6D6D] lg:h-[218px] h-[140px] sm:h-[120px]'> 
            <div className='lg:pl-10 lg:pr-10'>
                <div id='footer-text1' className='text-white   lg:flex lg:justify-center lg:pt-[32px] pt-[20px] lg:text-[25px] text-[10px] sm:flex sm:justify-center sm:text-[12px] ' >
                    <p className="lg:pr-[29px] lg:ml-0 ml-[21px]">Junction Med Co.</p>
                    <p className="lg:pr-[29px]  lg:ml-0 ml-[21px]  ">Headquarters: 83, Sangjidae-gil, Wonju-si, Gangwon-do, Republic of Korea</p>
                    <p className="lg:pr-[29px]  lg:ml-0 ml-[21px] ">CEO  :  Jimin Park </p>
                    <p className=" lg:ml-0 ml-[21px] ">Business registration number  :  440-86-02331 </p>
                </div>
                <div id='footer-text2' className='text-white  lg:flex justify-center lg:pt-[12px]  lg:ml-0 ml-[21px] sm:flex sm:text-[12px]' >
                    <p id='footer-text2'className="lg:pr-[29px] lg:text-[25px] text-[10px] sm:pr-4 sm:text-[12px] ">Tel  :  +82-2-6951-2788</p>
                    <p id='footer-text2'className="lg:pr-[29px] lg:text-[25px] text-[10px] sm:text-[12px]">Copyright 2024 Junction Med Co. All rights reserved. </p>
                    <Link to='/agreement-service'  className='lg:pr-[29px] lg:text-[25px] text-[10px] sm:text-[12px] cursor-pointer' >Terms of Use</Link>
                    <Link to='/agreement-privacy' className='lg:pr-[29px] lg:text-[25px] text-[10px] sm:text-[12px] cursor-pointer' >Privacy Policy</Link>
               
                </div>
               <div className=' '>
                   
               </div>
               <div>
                  
               </div>
               
            </div>
        </footer>
        </>
   
    )
  }

  export default footer