import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/footer';
import PositionBg from '../../Img/positionBG.png';
import logo from '../../Img/202407.png';
// import logo1 from '../../Img/partnerLogo/logo_1.png';
// import logo2 from '../../Img/partnerLogo/logo_2.png';
// import logo3 from '../../Img/partnerLogo/logo_3.png';
// import logo4 from '../../Img/partnerLogo/logo_4.png';
// import logo5 from '../../Img/partnerLogo/logo_5.png';
// import logo6 from '../../Img/partnerLogo/logo_6.png';
// import logo7 from '../../Img/partnerLogo/logo_7.png';
// import logo8 from '../../Img/partnerLogo/logo_8.png';
// import logo9 from '../../Img/partnerLogo/logo_9.png';
// import logo10 from '../../Img/partnerLogo/logo_10.png';
// import logo11 from '../../Img/partnerLogo/logo_11.png';
// import logo12 from '../../Img/partnerLogo/logo12.png';
// import logo121 from '../../Img/partnerLogo/logo_12.png';
// import logo13 from '../../Img/partnerLogo/logo_13.png';
// import logo14 from '../../Img/partnerLogo/logo_14.png';
// import logo15 from '../../Img/partnerLogo/logo_15.png';
// import logo17 from '../../Img/partnerLogo/logo_17.png';
// import logo19 from '../../Img/partnerLogo/logo_19.png';
// import logo20 from '../../Img/partnerLogo/logo_20.png';
// import logo21 from '../../Img/partnerLogo/logo_21.png';
// import logo22 from '../../Img/partnerLogo/logo_22.png';
// import logo24 from '../../Img/partnerLogo/logo_24.png';
// import logo25 from '../../Img/partnerLogo/logo_25.png';
// import logo26 from '../../Img/partnerLogo/logo_26.png';
// import logo28 from '../../Img/partnerLogo/logo28.png';
// import logo31 from '../../Img/partnerLogo/logo31.png';
// import logo32 from '../../Img/partnerLogo/logo33.png';
// import logo34 from '../../Img/partnerLogo/logo36.png';
// import logo38 from '../../Img/partnerLogo/logo_38.png';
// import logo40  from '../../Img/partnerLogo/logo40.png';
// import logo41 from '../../Img/partnerLogo/logo41.png';
// import logonew1 from '../../Img/partnerLogo/logonew1.png';
// import logonew2 from '../../Img/partnerLogo/logonew2.png';
// import logonew3 from '../../Img/partnerLogo/logonew3.png';



const PartnerPage = () => {
    const [isImageLoaded, setImageLoaded] = useState(false);
    const navigate = useNavigate();

    const handleButtonClick = (): void => {
        navigate('/');
    }


  return (
    <>
      <div className='w-full '>
        <div className='w-full'>
          <img
              src={PositionBg}
              className='w-full lg:pt-0 pt-[76px]'
              onLoad={() => setImageLoaded(true)}
              style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }} 
          />
        </div>
        {isImageLoaded && (
          <>
            <div className='w-full  justify-left lg:ml-[123px] ml-[16px] lg:mt-[123px] mt-[40px] '>
                <div className=''>
                    <div className='lg:text-[80px] text-[26px] font-extrabold sm:pl-20 sm:text-[30px]'>협력</div>
                    <div className='lg:text-[55px] text-[14px] font-normal sm:pl-20 sm:text-[30px]'>MOU기관</div>
                </div>
            </div>
            <div className='lg:mt-[122px] lg:mb-[202px]  mb-20 w-full lg:pl-0 pl-10  lg:pr-0 pr-10'>
              <div className=' w-full flex justify-center '>
                <img src={logo} className='w-[90%]'/>
                {/*   <div className='flex justify-center lg:ml-0 lg:mr-0 ml-10 mr-10'>
                        <img src={logo1} className='lg:mr-20  lg:h-[55px] h-[25px] mr-2'/>
                        <img src={logo2} className='lg:mr-20  lg:h-[55px] h-[25px] mr-2'/>
                        <img src={logo11} className='lg:mr-16 lg:h-[55px] h-[25px] mr-2'/>
                        <img src={logo3} className='lg:mr-16 lg:h-[55px]   h-[25px] mr-2'/>
                        <img src={logo4} className=' lg:mr-20  lg:h-[55px]  h-[25px] mr-2'/>
                    
                        <img src={logo6} className='lg:h-[55px]  h-[20px]'/>
                    </div>
                    <div className='flex justify-center mt-[40px] '>
                        <img src={logo7} className='lg:mr-10 lg:h-[55px] h-[25px] mr-2'/>
                        <img src={logo8} className='lg:mr-10 lg:h-[35px] lg:mt-2 h-[15px] mr-2 w-[70px] lg:w-[180px]'/>
                        <img src={logo9} className='lg:mr-10 lg:h-[55px] h-[20px]  mr-2 '/>
                        <img src={logo20} className='lg:mr-10 lg:h-[35px] lg:mt-2 h-[15px]  mr-2 '/>
                        <img src={logo26} className='lg:mr-10 lg:h-[35px] lg:mt-2 h-[15px]  mr-2 '/>
                        <img src={logo38} className='lg:mr-10 lg:h-[55px] h-[20px]   '/>
                        <img src={logo12} className='lg:mr-10 lg:h-[55px] h-[20px] '/>
                </div>
                  <div className='flex justify-center mt-[40px]'>
                        <img src={logo121} className='lg:mr-24  lg:h-[50px] h-[20px] mr-4'/>
                        <img src={logo13} className=' lg:mr-24  lg:h-[55px] h-[25px] mr-2'/>
                        <img src={logo21} className='lg:mr-20  h-[35px] lg:h-[60px] mr-4'/>
                        <img src={logo41} className='lg:mr-24  lg:h-[35px] lg:mt-4 h-[15px] mr-2 lg:mt-0 mt-2'/>
                        <img src={logo14} className=' lg:mr-20  lg:h-[35px] lg:mt-4 h-[15px] mr-4 lg:mt-0 mt-2'/>
                        <img src={logo32} className=' lg:h-[55px] h-[30px]'/>
        
                    </div>
                    <div className='flex justify-center mt-[40px]'>
                        <img src={logo17} className='lg:mr-20  lg:h-[55px] h-[25px] mr-2'/>
                        <img src={logo34} className='lg:mr-20  lg:h-[30px] lg:mt-4 h-[15px] mr-2 lg:mt-0 mt-1'/>
                        <img src={logo19} className='lg:mr-20  lg:h-[55px] h-[25px] mr-2'/>
                        <img src={logo25} className='lg:mr-20  lg:h-[35px] lg:mt-4 h-[20px] mr-2 mt-1'/>
                        <img src={logo10} className='lg:mr-20  lg:h-[65px] h-[25px] mr-2'/>
                        <img src={logo40} className='lg:h-[55px] lg:mr-10 h-[25px]'/>
                    
                    </div>
                    <div className='flex justify-center mt-[40px]'>
                        <img src={logo22} className='lg:mr-20  lg:h-[45px]  h-[15px] mr-4 '/>
                        <img src={logo24} className='lg:mr-20  lg:h-[20px] lg:mt-4  h-[8px]  mr-4 mt-1'/>
                        <img src={logo15} className='lg:mr-20  lg:h-[35px] lg:mt-4  h-[20px] mr-2  '/>
                        <img src={logo5} className='lg:mr-20  lg:h-[55px]  h-[20px]'/>
                        <img src={logo31} className='lg:mr-20  lg:h-[55px]  h-[25px]'/>
                        <img src={logo28} className=' lg:h-[55px]  h-[35px]'/>

                    </div>
                  <div className='flex justify-center mt-[32px]'>
                        <img src={logonew1} className='lg:mr-20  lg:h-[35px]  h-[18px] mr-4'/>
                        <img src={logonew2} className='lg:mr-20  lg:h-[25px] lg:mt-4  h-[15px] mr-4'/>
                        <img src={logonew3} className='lg:mr-[610px] lg:h-[45px]   h-[25px] mr-36'/>
                
                    </div> */}
              </div>
            </div>
          </>
        )}
      </div>
      {isImageLoaded && (
        <Footer/>
      )}
    </>
  );
};

export default PartnerPage;