import { createBrowserRouter, Navigate } from 'react-router-dom';
// import LogoutRoot from './pages/common/LogoutRootPage';
// import LoginRoot from './pages/common/LoginRootPage';
// import PostListPage from './pages/PostListPage';
import LoginPage from './common/login';
// import TestPage from './pages/LoginPage_sky'
// import RegisterPage from './pages/RegisterPage';
// import WritePage from './pages/WritePage';
// import PostPage from './pages/PostPage';
import MainPage from './pages/mainPage/mainPage';
// import ErrorComponent from './pages/common/ErrorPage'
// import NotFoundComponent from './pages/common/NotFoundPage'

const Router = () => {


  const logoutRouter = createBrowserRouter([
    {
        
      path: '/',
    //   element: <LogoutRoot />,
      children: [
        {
          index: true,
          element: <MainPage />,
        //   errorElement: <ErrorComponent />,
        },
      ],
      errorElement: <Navigate to={'/login'} />,
    },
  ]);

  const loginRouter = createBrowserRouter([
    {
      path: '/admin-login',
      element: <LoginPage />,
      children: [
        {
          index: true,
          
          errorElement: <Navigate to={'/admin-login'} />,
        },
      

      ],
    //   errorElement: <NotFoundComponent />,
    },
  ]);

  return { logoutRouter, loginRouter };
};

export default Router;
