import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/footer';
import Modal from '../../common/modal/warningComponent';
import MediaBg from '../../Img/mediaBG.png';
import { fetchUserBoardList } from '../../lib/api/adminApi';

interface ListItem {
  TITLE: string;
  WRITE_DT: string;
  WRITE_SEQ: number;
  NOTICE_YN: string;
  TITLE_IMG_PATH: string;
  START_DT: string;
  END_DT: string;
  SEQ: number;
  BOARD_GBN: string;
  CONTENTS: string;
}

const MediaPage = () => {
  const [isImageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();
  const [boardList, setBoardList] = useState<ListItem[]>([]);
  const [showReady, setShowReady] = useState<boolean>(false);

  const getBoardList = async () => {
    try {
      const inputData = {
        in_gbn: 'ALL',
        in_seq: '',
        in_board_gbn: '보도자료',
        in_keyword: '',
      };

      console.log('save input:' + JSON.stringify(inputData));

      const { data } = await fetchUserBoardList(inputData);

      console.log('B:' + JSON.stringify(data));

      if (data.success) {
        setBoardList(data.data);
        localStorage.setItem('setBoardList', JSON.stringify(data));
      } else {
        // Handle failure
      }
    } catch (e) {
      // Handle error
    }
  };

  useEffect(() => {
    getBoardList();
  }, []);

  const ViewClick = (seq: any) => {
    navigate('/user-editorBView', { state: { seq: seq } });
  };

  return (
    <div>
      <img
        src={MediaBg}
        className="w-full lg:pt-0 pt-[76px]"
        onLoad={() => setImageLoaded(true)}
        style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }}
      />
      {isImageLoaded && (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flow-root mb-20 lg:ml-[10%] lg:mr-[10%]">
            <div className="lg:mt-[169px] mt-10">
              <div className="border-b-4 border-[#0085FF]">
                <h1 className="lg:text-[98px] text-[26px] font-bold">보도자료</h1>
              </div>
            </div>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 pl-4 pb-24 lg:mr-0 mr-2">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr className="font-bold">
                      <th className="py-3.5 text-left text-lg font-bold text-gray-900">제목</th>
                      <th className="py-3.5 text-left text-lg font-bold text-gray-900">날짜</th>
                    </tr>
                  </thead>
                  {boardList.length > 0 && (
                    <tbody className="divide-y divide-gray-200">
                      {boardList.map((list) => (
                        <tr key={list.SEQ} className="hover:bg-[#E0F4FF] cursor-pointer">
                          <td
                            className="whitespace-nowrap lg:text-lg text-xs font-medium text-gray-900"
                            onClick={() => ViewClick(list.SEQ)}
                          >
                            {list.TITLE}
                          </td>
                          <td
                            className="whitespace-nowrap py-4 text-sm text-gray-500"
                            onClick={() => ViewClick(list.SEQ)}
                          >
                            {list.WRITE_DT}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            {/* Optional action buttons can go here */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {isImageLoaded && <Footer />}
    </div>
  );
};

export default MediaPage;
