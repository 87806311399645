import React from 'react';
import { useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FaArrowUpFromBracket, FaX } from "react-icons/fa6";

type TCenterInputFileProps = {
  inputFileRef: React.RefObject<HTMLInputElement>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  isLast?: boolean;
};

const InfoInputFile = ({ isLast, inputFileRef, watch, setValue }: TCenterInputFileProps): JSX.Element => {
  const [fileNameInfo, setFileNameInfo] = useState<string[]>([]);
  
  return (
    <div className='flex'>
      <div className='lg:w-[20%] flex  pl-4'>
        <div className='flex w-auto justify-center  lg:gap-2'></div>
        <div 
          onClick={(): void => {
            if (fileNameInfo?.length >= 4) {
              return;
            }
            inputFileRef.current?.click();
          }}
        >
          <div className='flex w-full justify-center'>
            <FaArrowUpFromBracket className='text-gray-500 cursor-pointer'/>
            <p className='pl-4 text-gray-500 text-sm cursor-pointe '>파일 올리기</p>
          </div>
        </div>
        <input 
          className='hidden'
          ref={inputFileRef}
          type='file'
          onChange={(e) => {
            if (e.target.files) {
              const fileExtension = e.target.files[0].name.split('.').pop()?.toLowerCase() || '';
              const fileExtensionArray = ['jpg', 'jpeg', 'png', 'pdf', 'xlsx', 'docx'];
              if (!fileExtensionArray.includes(fileExtension)) {
                return;
              }
              if (e.target.files[0].size > 10 * 1024 * 1024) {
                return;
              }

              setFileNameInfo([...fileNameInfo, e.target.files[0].name]);
              setValue('Infofile', [...watch('Infofile'), e.target.files]);
            }
          }}
          multiple
        />
      </div>
      {fileNameInfo && fileNameInfo.length > 0 && (
        <div className='flex pl-1 w-full'>
          {fileNameInfo.map((name, index) => (
            <div key={index} className='flex'>
              <div className='pr-2'>{name}</div> 
              <FaX className='pl-1 pt-1 text-lg cursor-pointer'
                onClick={() => {
                  setValue(
                    'Infofile',
                    watch('Infofile').filter((_: any, i: number) => i !== index),
                  );
                  setFileNameInfo(fileNameInfo.filter((_, i) => i !== index));
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InfoInputFile;