import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/footer';
import PositionBg from '../../Img/positionBG.webp';
import PositionGroup from '../../Img/positionGroup.png';
import './position.css';

const PositionPage = () => {

    const navigate = useNavigate();
    const [isImageLoaded, setImageLoaded] = useState(false);
    const handleButtonClick = (): void => {
        navigate('/');
    }


  return (
    <>
    <div className='w-full'>
      <img 
        src={PositionBg}
        className='w-full lg:pt-0 pt-[76px]' 
        width="100" height="100"
        onLoad={() => setImageLoaded(true)}
        style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }} />
        {isImageLoaded && (
          <>
        <div className='lg:h-[1080px]'>
          <div className='w-full  justify-left lg:ml-[123px] ml-[20px] lg:mt-[123px] mt-[30px]'>
              <div className='lg:text-[80px] text-[26px] font-extrabold'>
                  <div>내부 / 외부</div>
                  <div>구성</div>
              </div>
          </div>
          <div className='lg:mt-[209px] mt-10 relative lg:h-[1024px] mr-[10%] ml-[10%]'>
            <div className='flex justify-center'>
              <p id='textline1' className='lg:text-[28px] text-[6px] text-[#0085FF] text-center sm:text-[12px]'> 회사를 운영하는데 있어 핵심적인<br/> 엔진 역할을 하며,회사의 기본을 <br/>만들어 나가는 곳</p>
            </div>
            <div className='flex justify-center'>
              <img src={PositionGroup} className='lg:w-auto w-[180px]'/>
            </div>
            <div id= 'textline3' className='flex justify-left absolute left-[10%]'>
              <p  id='textline1' className='lg:text-[28px] text-[6px] text-[#0085FF] text-center sm:text-[12px]'> 현대 비즈니스 환경에서 중요한 <br/> 역할을 하는 개발과 데이터분석을<br/> 위주로 활동</p>
            </div>
            <div  id= 'textline2'className='flex justify-end absolute right-[10%]'>
              <p id= 'textline1' className='lg:text-[28px] text-[6px] text-[#0085FF] text-center sm:text-[12px]'>   서비스의 혁신을 만들어 나가기 위해  <br/>아이디어를 실체화하며, <br/>사용자에 맞게 서비스를 개선</p>
            </div>
          </div>
        </div>
        </>
        )}
        <div className='lg:mt-[550px] lg:mb-[219px] flex justify-center mt-20 mb-10'>
          <div className='border-b-4 w-[90%]  border-[#E0F4FF] '></div>
        </div>
        <div className='lg:flex justify-center'>
          <div className=''>
            <div   id='twosection' className='lg:ml-0 ml-5  '>
              <div className='flex items-center'>
                <div className='lg:border-[8px] border-[1px] lg:h-[51px] h-[15px] border-[#0085FF]'></div>
                <div className='pl-[8px] lg:text-[46px] text-[14px] font-extrabold'>대표 이사 </div>
              </div>
              <div className=''>
                <div id='twosection1'  className='lg:w-[332px] lg:h-[172px] sm:w-[120px] sm:h-[65px] w-[90px] h-[50px] shadow-lg lg:pl-[34px] rounded-lg pl-2'>
                  <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2 sm:text-[12px] '>총괄</div>
                  <div className='lg:w-[260px] lg:h-[68px] sm:w-[90px] sm:h-[30px] w-[64px] h-[16px] bg-[#f0f9ff] text-[28px] text-[#858585]'>
                    <div className='flex justify-end plg:r-2 lg:pt-8 text-[10px] lg:text-[24px] pt-1 sm:text-[14px] sm:pt-2'>박지민</div>
                  </div>
                </div>
              </div>
            </div>
            <div id='twosection'  className='lg:mt-[69px] mt-10 lg:ml-0 ml-5 ' >
              <div className='flex items-center'>
                <div className='lg:border-[8px] border-[1px] lg:h-[51px] h-[15px] border-[#0085FF]'></div>
                <div className='pl-[8px] lg:text-[46px] text-[14px] font-extrabold'>기술 </div>
              </div>
              <div className=''>
                <div id='twosection2' className='lg:w-[700px] lg:h-[430px] sm:w-[350px] sm:h-[150px] w-[200px] h-[120px] shadow-lg lg:pl-[34px] flex rounded-lg pl-2'>
                  <div>
                    <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2 sm:text-[12px]'>앱 / 웹 개발</div>
                      <div id='twosection6' className='lg:w-[260px] lg:h-[340px] sm:w-[120px] sm:h-[100px] w-[80px] h-[40px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px] sm:text-[9px]'>
                      <div id='twosection5' className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pb-4 pr-1 pt-2 pl-1 '>기술중심의 핵심으로서, 자사내에 있는 개발 및 유비보수에 관련 된 프로젝트를 담당</div>
                    </div>
                    </div>
                    <div  id='twosection7' className='lg:ml-[74px] ml-4'>
                      <div  className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2 sm:text-[12px]'>DB/분석 고도화</div>
                        <div id='twosection6' className='lg:w-[260px] lg:h-[340px] sm:w-[120px] sm:h-[100px] w-[64px] h-[90px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px] sm:text-[9px]'>
                          <div id='twosection5'  className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pr-1 pt-2 pl-1'>데이터베이스 설계 및 구축, 데이터를 기반으로하는 경쟁력,비즈니스,서비스 등 데이터의 가치를 극대화하기 위한 고도화 작업을 하는 데이터 중심 비즈니스의 프로젝트를 담당</div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div  id='twosection' className='lg:mt-[69px] mt-10 lg:ml-0 ml-5'>
              <div className='flex items-center'>
                <div className='lg:border-[8px] border-[1px] lg:h-[51px] h-[15px] border-[#0085FF]'></div>
                <div className='pl-[8px] lg:text-[46px] text-[14px] font-extrabold'>경영 </div>
              </div>
              <div className=''>
                <div id='twosection3'  className='lg:w-[1100px] lg:h-[384px] sm:w-[500px] sm:h-[160px] w-[250px] h-[90px] shadow-lg lg:pl-[34px] flex rounded-lg pl-2'>
                  <div>
                    <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2  sm:text-[12px]'>경영전략</div>
                      <div   id='twosection6' className='lg:w-[260px] lg:h-[300px] sm:h-[100px] sm:w-[120px] w-[64px] h-[60px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px] sm:text-[10px]'>
                      <div  id='twosection5' className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pr-1 pt-2 pl-1' >회사의 장기적 비전과 목표를 설정하고, 이를 달성하기 위한 전략적 계획과 실행을 담당</div>
                    </div>
                    </div>
                    <div  id='twosection7' className='lg:ml-[74px] ml-4'>
                      <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2 sm:text-[12px]'>사무/행정 지원</div>
                        <div  id='twosection6'  className='lg:w-[260px] lg:h-[300px]  sm:h-[100px] sm:w-[120px]  w-[64px] h-[60px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px]  sm:text-[10px]'>
                          <div id='twosection5'  className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pr-1 pt-2 pl-1'>문서 작성 및 관리, 부서 회의지원, 조직 내외 업무지원 등 운영에 관련된 지원을 담당</div>
                      </div>
                    </div>
                    <div id='twosection7'  className='lg:ml-[74px] ml-4'>
                      <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2 sm:text-[12px]'>계약관리</div>
                        <div  id='twosection6'  className='lg:w-[260px] lg:h-[300px]   sm:h-[100px] sm:w-[120px]  w-[64px] h-[60px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px]  sm:text-[10px]'>
                          <div id='twosection5' className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pr-1 pt-2 pl-1'>기업이나 기관과의 계약에 있어 접수하거나, 검토등 절차상의 다양한 서무업무에 대한 지원등을 담당</div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div id='twosection'  className=' lg:mt-[69px] mt-10 lg:ml-0 ml-5 mb-20'>
              <div className='flex items-center'>
                <div className='lg:border-[8px] border-[1px] lg:h-[51px] h-[15px] border-[#0085FF] '></div>
                <div className='pl-[8px] lg:text-[46px] text-[14px] font-extrabold'>서비스 </div>
              </div>
              <div className=''>
                <div id='twosection0' className='lg:w-[1420px] sm:w-[650px] sm:h-[160px] lg:h-[420px] w-[340px] h-[120px] shadow-lg lg:pl-[34px] flex rounded-lg pl-2 '>
                  <div>
                    <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2 sm:text-[12px]'>서비스기획 /전략</div>
                      <div id='twosection6'  className='lg:w-[260px] lg:h-[300px] sm:w-[120px] sm:h-[100px] w-[64px] h-[90px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px] pb-4 sm:text-[10px]'>
                      <div id='twosection5' className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pr-1 pt-2 pl-1'>서비스의 방향성을 정하고, 그에 따른 전략을 수립하는 역할을 하고, 고객의 필요와 기대를 충족시키는 혁신적인 서비스 전략 등 신사업 기회 발굴등을 담당</div>
                    </div>
                    </div>
                    <div  id='twosection7' className='lg:ml-[74px] ml-4'>
                      <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2 sm:text-[12px]'>디자인개발</div>
                        <div  id='twosection6' className='lg:w-[260px] lg:h-[320px]  sm:w-[120px] sm:h-[100px] w-[64px] h-[60px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px] sm:text-[10px]'>
                          <div  id='twosection5' className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pr-1 pt-2 pl-1'>제품이나 서비스의 디자인 컨셉을 수립, 이를 시각적으로 구체화하여 표현하는 업무를 담당</div>
                      </div>
                    </div>
                    <div id='twosection7'  className='lg:ml-[74px] ml-4'>
                      <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2 sm:text-[12px]'>마케팅 전략 / 홍보</div>
                        <div  id='twosection6' className='lg:w-[260px] lg:h-[320px]  sm:w-[120px] sm:h-[100px] w-[64px] h-[90px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px] sm:text-[10px]'>
                          <div  id='twosection5' className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pr-1 pt-2 pl-1'>회사의 기본적인 사업 방향에 따라 마케팅 전략을 수립하고, 이를 중심으로 제품과 서비스가 시장 내 위치 확인 및 타겟을 확보하고, 고객에게 홍보를 하는 업무를 담당 </div>
                      </div>
                    </div>
                    <div id='twosection7'  className='lg:ml-[74px] ml-4'>
                      <div className='text-[#323232] lg:text-[28px] text-[10px] lg:pt-[28px] lg:pl-[10px] font-bold pt-2'>케미몰 운영 </div>
                        <div  id='twosection6' className='lg:w-[260px] lg:h-[320px] sm:w-[120px] sm:h-[100px]  w-[64px] h-[60px] bg-[#f0f9ff] lg:text-[24px] text-[#858585] text-[6px] sm:text-[10px] '>
                          <div  id='twosection5' className='flex jutstify-center lg:pl-[10px] lg:pt-[28px] pr-1 pt-2 pl-1'>건강에 관련된 기능식품을 판매하는 건기식, 건강용품등을 판매하는 몰의 운영을 담당</div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    <div>
    </div>
     
    </div>
    {isImageLoaded && (
     <Footer/>
    )}
    </>
  );
};

export default PositionPage;