import { useState,useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../Img/wt_logo.png';
import MLogo from '../Img/Mlogo.png';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const navigation = [
  { 
    name: '회사소개', 
    to: '/info', 
   /*  subMenu: [
      { name: '회사소개', to: '/info' }, 
      { name: '인사말', to: '/info' }, 
      { name: '연혁', to: '/info' }
    ]  */
  },
  { 
    name: '서비스', 
    to: '/service', 
    subMenu: [
      { name: '케미와 케어봄', to: '/service' }, 
      { name: '서비스 소개', to: '/serviceinfo' }, 
      { name: '케미몰', to: 'https://smartstore.naver.com/camimall' }
    ] 
  },
  { 
    name: '구성', 
    to: '/position', 
    subMenu: [
      { name: '내/외부 구성', to: '/position' }, 
      { name: '파트너', to: '/partner' }
    ] 
  },
  { 
    name: '미디어', 
    to: '/media', 
    subMenu: [
      { name: '보도자료', to: '/media' }, 
      { name: '새소식', to: '/mediaNews' }, 
      { name: '블로그', to: 'https://blog.naver.com/junction_med' }
    ] 
  },
  { name: '문의', to: '/contactMain' },
];

// 예시 데이터
const urls = [
  { url: 'info', url_nm: '회사소개' },
  { url: 'service', url_nm: '서비스' },
  { url: 'serviceinfo', url_nm: '서비스' },
  { url: 'position', url_nm: '구성' },
  { url: 'partner', url_nm: '구성' },
  { url: 'media', url_nm: '미디어' },
  { url: 'mediaNews', url_nm: '미디어' },
  { url: 'contactMain', url_nm: '문의' }
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState('');
  const [activeNavItem, setActiveNavItem] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;
  // 현재 경로에서 '/'를 기준으로 분할하여 배열로 만듦
  const pathParts = currentPath.split('/');

  // 첫 번째 요소를 제외한 나머지 부분을 가져와서 조합하여 실제 경로를 구함
  const currentURL = pathParts.slice(1).join('/');

  // 현재 경로와 일치하는 url_nm을 찾기 위해 배열에서 객체를 찾음
  const matchingURL = urls.find(item => item.url === currentURL);

  // 매칭되는 객체가 있으면 해당 객체의 url_nm 반환, 없으면 빈 문자열 반환
  const urlName = matchingURL ? matchingURL.url_nm : '';
  const [clickedMenuName, setClickedMenuName] = useState("");
  const goHome = () => {
    window.location.href = '/';
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleMouseEnter = (name:string) => {
    setOpenSubMenu(name);
  };

  const handleMouseLeave = () => {
    setOpenSubMenu('');
  };

  const handleNavItemClick = (name: string) => {
    setActiveNavItem(name); // 주 메뉴 클릭 시 상태 업데이트
    setClickedMenuName(name);
  };
  
  const handleSubMenuClick = (name: string, to: string) => {
    setActiveNavItem(name); // 하위 메뉴 클릭 시 상태 업데이트
  
    if (to === '/info') {
      scrollToInfo();
    } else {
      window.location.href = to;
    }
  };
  const scrollToInfo= () => {
    const greetingSection = document.getElementById("Info");
    if (greetingSection) {
        greetingSection.scrollIntoView({ behavior: "smooth" });
    }
};
  
  
  const Goinfo = () => {
    window.location.href = '/info';
  }
  const GoService = () => {
    window.location.href = '/service';
  }
  const GoServiceinfo = () => {
    window.location.href = '/serviceinfo';
  }
  const GoPosition = () => {
    window.location.href = '/position';
  }
  const GoPartner = () => {
    window.location.href = '/partner';
  }
  const GoMedia = () => {
    window.location.href = '/media';
  }
  const GoContact = () => {
    window.location.href = '/contactMain';
}
const GoMall = () => {
  window.location.href = 'https://smartstore.naver.com/camimall';
}
const GoBlog = () => {
  window.location.href = 'https://blog.naver.com/junction_med';
}
const GoNews = () => {
  window.location.href = '/mediaNews';
}
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // 디자인에 맞게 너비 조정
  };

  handleResize(); // 초기 체크
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  return (
    <>
      <header className="w-full z-10 bg-[#383838] fixed opacity-[75%] h-[76px] ">
        <nav className="mx-auto flex max-w-7xl items-center lg:px-8 p-6 " aria-label="Global">
          <div className="flex ">
            
            <a href="#" className="">
              <span className="sr-only">Your Company</span>
              {isMobile ? (
                <img className="h-8 w-auto absolute left-6 top-5" src={MLogo} alt="" onClick={goHome} />
              ) : (
                <img className="h-8 w-auto absolute left-6 top-5" src={Logo} alt="" onClick={goHome} />
              )}
            </a>
            <div className=' text-white text-[22px] font-bold absolute top-6 w-[120px] left-[42%] lg:hidden'>{urlName}</div>
          </div>
          
          <div className="flex lg:hidden mr-2 absolute right-4 top-6">
            
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md  text-white"
              onClick={toggleMobileMenu}
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            
          </div>
          
          <div className="hidden lg:flex lg:gap-x-20 ml-48">
          {navigation.map((item) => (
              <div
                key={item.name}
                className=""
                onMouseEnter={() => handleMouseEnter(item.name)}
                /* onMouseLeave={handleMouseLeave} */
              >
                <Link
                  to={item.to}
                  className={`text-white text-[28px] leading-6 ${
                    activeNavItem === item.name
                      ? 'border-b-4 border-[#E0F4FF] pb-4 w-full font-semibold'
                      : ''
                  }`}
                  onClick={() => handleNavItemClick(item.name)}
                >
                  {item.name}
                </Link>
                {openSubMenu === item.name && item.subMenu && (
                  <div className="absolute w-full bg-[#383838] z-10 left-0 h-[77px] flex mt-[22px]">
                    <div className="flex justify-center items-center w-[90%]">
                      {item.subMenu.map((subItem) => (
                        <div
                          key={subItem.name}
                          className="text-[28px] text-white flex justify-end pr-6 cursor-pointer"
                          onClick={() => handleSubMenuClick(subItem.name, subItem.to)}
                        >
                          <span className="flex justify-center">{subItem.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
          
            </a>
          </div>
        </nav>
      </header>
   
      {/* 모바일 메뉴 다이얼로그 */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={toggleMobileMenu}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#0085FF] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className=""
                src={MLogo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="w-10 h-10 text-white rounded-md  p-1.5 text-white"
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className='text-lg' />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <div>
                  <div className='flex'>
                    <div className='text-[24px] text-white font-bold  w-[50%]'>회사소개</div>
                    <div className='border w-full h-0 mt-4'></div>
                  </div>
                  <div className='flex justify-end'>
                    <a className='text-[20px] text-white cursor-pointer' onClick={Goinfo}>회사소개</a>
                  </div>
                  <div className='flex'>
                    <div className='text-[24px] text-white font-bold  w-[50%]'>서비스</div>
                    <div className='border w-full h-0 mt-4'></div>
                  </div>
                  <div className=''>
                    <div className='text-[20px] text-white flex justify-end cursor-pointer'onClick={GoService} >케미와 케어봄</div>
                    <div className='text-[20px] text-white flex justify-end pt-[14px] cursor-pointer'onClick={GoServiceinfo}>서비스 소개</div>
                    <div className='text-[20px] text-white flex justify-end pt-[14px] cursor-pointer'onClick={GoMall}>케미몰</div>
                  </div>
                  <div className='flex'>
                    <div className='text-[24px] text-white font-bold  w-[50%] cursor-pointer'>구성</div>
                    <div className='border w-full h-0 mt-4'></div>
                  </div>
                  <div className=''>
                    <div className='text-[20px] text-white flex justify-end cursor-pointer'onClick={GoPosition}>내부/외부 구성</div>
                    <div className='text-[20px] text-white flex justify-end pt-[14px] cursor-pointer'onClick={GoPartner}>파트너</div>
                  </div>
                  <div className='flex'>
                    <div className='text-[24px] text-white font-bold  w-[50%]'>미디어</div>
                    <div className='border w-full h-0 mt-4'></div>
                  </div>
                  <div className=''>
                    <div className='text-[20px] text-white flex justify-end cursor-pointer' onClick={GoMedia}>보도자료</div>
                    <div className='text-[20px] text-white flex justify-end pt-[14px] cursor-pointer' onClick={GoNews}>새소식</div>
                    <div className='text-[20px] text-white flex justify-end pt-[14px] cursor-pointer' onClick={GoBlog}>블로그</div>
                  </div>
                  <div className='flex'>
                    <div className='text-[24px] text-white font-bold  w-[50%]' >문의</div>
                    <div className='border w-full h-0 mt-4'></div>
                  </div>
                  <div className=''>
                    <div className='text-[20px] text-white flex justify-end cursor-pointer' onClick={GoContact}>문의</div>
                  </div>
                </div>

               {/*  {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-[#B0E4FF] hover:text-gray-500"
                  >
                    {item.name}
                  </a>
                ))} */}
              </div>
              <div className="py-6">
                {/*    <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a> */}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}