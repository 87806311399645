import { useState, useEffect } from 'react'
import Visual from '../../../Img/visual-bg.png';
import MainPhone from '../../../Img/mainPhone.png';
import Underline from '../../../Img/underline.png';
import Shadow from '../../../Img/shadow.png';
import './visual.css';

import { useNavigate } from 'react-router-dom';

interface ISuccessInfo {
    tot_cnt: number;
    success_cnt: number;
    fail_cnt: number;
    standby_cnt: number;
    success_rate: number;
}


const VisualComponent = () => {
    const [isImageLoaded, setImageLoaded] = useState(false);



  
    return (
        <>
      
        <div className='relative lg:h-[1078px] h-[700px]'>
            <img 
                src={Visual} 
                className='absolute inset-0 w-full h-full object-cover'
               /*  onLoad={() => setImageLoaded(true)}
                style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }} /> */
                />
             <div className='absolute inset-0 bg-[#0085FF] bg-opacity-80 '>
           
                
                <div className='lg:flex items-center justify-center lg:mt-[200px] mt-[100px] '>
            
                {isImageLoaded && (
                    <>
                    <div id='ttline' className='leading-tight mr-10'> 
                        <h1 id='maintext' className='lg:text-[120px] lg:ml-0 ml-[5%] text-[42px]  text-white font-bold  '>시니어</h1>
                        <h1  id='maintext' className='lg:text-[120px] lg:ml-0 ml-[5%] text-white font-bold  text-[42px] '>건강관리</h1>
                        <h1  id='maintext' className='lg:text-[120px] lg:ml-0 ml-[5%] text-[#B0E4FF] font-bold text-[42px]  '>시작은</h1>
                        <div  id='ttline2' className='flex leading-tight'>
                            <div  id='maintext' className='lg:text-[120px] lg:ml-0 ml-[5%] text-white font-bold lg:text-right leading-tight  text-[42px] '>
                                정션메드
                                <img 
                                    id='img2' 
                                    src={Underline}
                                    className='lg:w-[350px] w-[150px] ' 
                                    onLoad={() => setImageLoaded(true)}
                                    style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }} />
                            </div>
                            <span id='maintext' className='lg:text-[120px]  text-[42px] text-[#B0E4FF] font-bold leading-tight '>와 함께</span>
                            
                    </div>
                    
                    
                </div> 
                </>
                )}
                
                
                <div className='img-line relative'>
                    <img 
                        src={MainPhone} 
                        className='lg:w-[800px] sm:w-[400px] sm:ml-10 lg:ml-0 ml-[6%]'
                        onLoad={() => setImageLoaded(true)}
                        style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }} 
                    />
                        
                    <img
                        src={Shadow} 
                        className='absolute lg:bottom-6 lg:w-[600px] bottom-3 left-1 w-[350px] '
                        onLoad={() => setImageLoaded(true)}
                        style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }} 
                    />
                </div>
               
                </div>
                <div></div>
                {/* <div> <h1 className='text-[145px] text-white font-bold'>시니어</h1></div> */}
               
            </div>
        </div>
       
        </>
    )
}


export default VisualComponent;