import { useState, useEffect,useCallback } from 'react';
import Link from '@tiptap/extension-link';
import HardBreak from '@tiptap/extension-hard-break';
import { FaBold, FaItalic, FaStrikethrough, FaCode, FaQuoteRight, FaAlignLeft, FaAlignCenter, FaAlignRight,FaFileImage } from 'react-icons/fa';
import { useEditor, EditorContent,Editor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image';
import { ListItem } from '@tiptap/extension-list-item';
import AddPhoto from './addPhoto';
import { TextAlign } from '@tiptap/extension-text-align';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { fetchAdminBoardModify } from '../../../lib/api/adminApi';
import Modal from '../../../common/modal/modalComponent';
import './editor.css';
import { set } from 'lodash';

interface IContent {
    content?: string;
  }


interface IActive {
  $isActive?: boolean;
}
interface Inputs {
  title:string;
	}

  
const ToolBar = ({ editor, content }: { editor: Editor | null; content: string }) => {
  
  
    // ! useCallback 모음
    const setLink = useCallback(() => {
      if (!editor) return;
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);
  
      if (url === null) {
        return;
      }
  
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
  
        return;
      }
  
      if (!url.startsWith('https://')) {
        window.alert('https://로 시작하는 URL 을 입력해주세요.');
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }
  
      // update link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }, [editor]);
  

    if (!editor) return null;
  
    const onInputHandler = (event: React.FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      editor.chain().focus().setColor(value).run();
      
    };
    const setTextAlignment = (alignment: string) => {
      if (!editor) return;
      editor.chain().focus().setTextAlign(alignment).run();
  };

    
    return (
      <div className="flex items-center justify-center  p-6 py-3 border-b-2 sm:gap-8 bg-gray-100 rounded-t-md">
        <div className="flex items-center justify-center gap-4">
          <div className='h-auto '>
            <input
            type="color"
            onInput={onInputHandler}
            className="w-5 h-6"
          />
          </div>
          <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}  // h1 토글 기능
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}  // 활성 상태 확인
                >
            h1
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
          >
            h2
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
          >
            h3
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
            className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
          >
            h4
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
            className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
          >
            h5
          </button>
            
          <FaBold
            onClick={() => editor.chain().focus().toggleBold().run()}
    
            className={editor.isActive('bold') ? 'is-active' : ''}
          />
          
          
          <FaItalic
            onClick={() => editor.chain().focus().toggleItalic().run()}
      
            className={editor.isActive('italic') ? 'is-active' : ''}
          />
          <button
                onClick={() => setTextAlignment('left')}
                className={editor?.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
            >
                <FaAlignLeft />
            </button>
            {/* 가운데 정렬 */}
            <button
                onClick={() => setTextAlignment('center')}
                className={editor?.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
            >
                <FaAlignCenter />
            </button>
            {/* 오른쪽 정렬 */}
            <button
                onClick={() => setTextAlignment('right')}
                className={editor?.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
            >
                <FaAlignRight />
            </button>
    
    
          <FaStrikethrough
            onClick={() => editor.chain().focus().toggleStrike().run()}
      
            className={editor.isActive('strike') ? 'is-active' : ''}
          />
    
          <FaCode
            onClick={() => editor.chain().focus().toggleCode().run()}
    
            className={editor.isActive('code') ? 'is-active' : ''}
          />

        <div className="flex items-center justify-center ">
    
          <AddPhoto editor={editor} />
        </div>
        <div>
          <button
            onClick={setLink}
            className={editor.isActive('link') ? 'is-active' : 'pt-1'}
          >
            Link
          </button>
        </div>          
      </div>
    </div>
    );
  }
  
  
const EditorComponent = ({ content }: { content: string }) => {
  const [editorContent, setEditorContent] = useState<string>(content);
	const [ ShowModal, setShowModal] = useState(false);
  const [inputs, setInputs] = useState<Inputs>({

    title:'',


  })


	const onChangeInfo = (e:any) => {
		//input에 name을 가진 요소의 value에 이벤트를 걸었다
		
		const { name, value } = e.target   
		const mkNm = 'chg';
		// 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
		const nextInputs = {            
			//스프레드 문법으로 기존의 객체를 복사한다.
			...inputs,  
			[name]: value,
			[mkNm+name]: true
		}
		//만든 변수를 seInput으로 변경해준다.
		setInputs(nextInputs)       

	}

    const editor: Editor | null = useEditor({
        extensions: [
          Image,
          TextStyle,
          Color.configure({ types: [TextStyle.name, ListItem.name] }),
          TextAlign.configure({
            types: ['heading', 'paragraph'],
            alignments: ['left', 'center', 'right'],
            defaultAlignment: 'left',
          }),
          StarterKit.configure({
            bulletList: {
              keepMarks: true,
              keepAttributes: false,
            },
            orderedList: {
              keepMarks: true,
              keepAttributes: false,
            },
          }),
          Link.configure({
            openOnClick: true,
          }),
          HardBreak.extend({
            /**
             * * 파일 다운로드 함수 (다운로드 버튼 클릭시 실행되는 함수)
             * @returns {void} 파일 다운로드 함수
             */
            addKeyboardShortcuts() {
              return {
                Enter: () => this.editor.commands.setHardBreak(),
              };
            },
          }),
        ],
        content: editorContent,
        onUpdate: ({ editor }) => {
            setEditorContent(editor.getHTML());
        },
    });
    
      if (!editor) {
        return null;
      }

    const handleSave = async () => {
      
        
			const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
	
			const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
		  
		   
			try {
	
				
				const inputData= {
	
          in_sts: 'I',
          in_seq: '',
          in_board_gbn: '뉴스',
          in_write_dt:'',
          in_write_seq:'',
          in_notice_yn:'',
          in_title_img_path:'',
          in_title:inputs.title,
          in_contents:editor.getHTML(),
          in_start_dt:'',
          in_end_dt:'',
          in_id: UserId,
      
				   
	
				}
					console.log('save input:'+ JSON.stringify(inputData));
			   
	
			/* 		return false; */
					const {data} = await fetchAdminBoardModify(inputData)
			
					console.log('onClickLogin end:'+ JSON.stringify(data))
						if(data.success){

              setShowModal(false);
              window.location.href = '/admin-login/user-editorNList';
						
						}else{
							
							alert('실패')
						}
					
					} catch (e) {
						// setError(e);
				}
    }
    return (
        <div className='lg:ml-20 lg:mr-20'>
            <div className='h-[200px] pt-[100px]'>
                <label className='font-bold '>게시글 제목</label>
                <input 
                  id='title'
                  name='title'
                  onChange={onChangeInfo}
                  type="text" placeholder="제목을 입력하세요" 
                  className='w-full h-[50px] border border-gray-200 rounded-md pl-2 mt-2'/>
            </div>
            <div className=" h-auto rounded-md">
                <ToolBar editor={editor}  content={editor.getHTML()}/>
                <div className=' pb-48  border rounded-b-md'>
					        <EditorContent editor={editor} />
                </div>
            </div>
            <div className='mb-20 flex justify-center mt-10'>
                <button className='bg-[#2999ff] text-[18px] text-white w-[180px] h-[40px] rounded-lg font-bold cursor-pointer hover:bg-blue-600' onClick={()=>setShowModal(true)}>게시글 등록</button>
            </div>
            {ShowModal &&(
            <Modal
            setIsModalOpen={() => setShowModal(false)}
            header={'등록'}
            sendMessage={'등록하시겠습니까?.'}
            storageMessage={'Yes버튼을 누르면 등록됩니다.'}
            handleSave={() => handleSave()}
            />
          )}
        </div>
     
    );
};

export default EditorComponent;