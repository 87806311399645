import { create } from 'zustand';
import { StorageValue, devtools, persist } from 'zustand/middleware';

// ! 로그인 state interface
export type TLogin = {
    USER_ID: string; // * 아이디
    USER_NM: string; // * 이름
    USER_RANK: string; // * 사용자 구분
    HP_NO: string; // *핸드폰 번호 
    token: string; // * 토큰
    imageUrl: string; // * 이미지 URL
    isFetching: boolean; // * 로딩 여부
    error: boolean; // * 에러 여부
};

type TLoginStore = {
    login: TLogin;
    setLogin: (login: TLogin) => void;
    setLogout: () => void;
};

export const initialLoginState: TLogin = {
    USER_ID: '',
    USER_NM: '',
    USER_RANK: '',
    HP_NO: '', 
    token: '',
    imageUrl: '',
    isFetching: false,
    error: false,
};

/**
 * * localStorage 로그인 정보를 저장하는 함수
 * @param {string} key 로컬스토리지 키
 * @returns {TLogin} 로그인 정보
 */
const getLocalStorage = (key: string): TLogin => JSON.parse(localStorage.getItem(key) ?? '{}');

export const useLoginStore = create<TLoginStore>()(
  devtools(
    persist(
      (set) => ({
        login: getLocalStorage('junction_login') ?? initialLoginState,
        setLogin: (login: TLogin): void => {
          set({ login });
        },
        setLogout: (): void => {
          set({ login: initialLoginState });
        },
      }),
      {
        name: 'junction_login',
        storage: {
          getItem: (name: string) => {
            const value = localStorage.getItem(name);
            if (!value) return null;
            return JSON.parse(value);
          },
          setItem: (name: string, value: StorageValue<TLoginStore>) => {
            const result = JSON.stringify(value.state.login);
            localStorage.setItem(name, result);
          },
          removeItem: (name: string) => {
            localStorage.removeItem(name);
          },
        },
      },
    ),
    {
      name: 'junction_login',
      trace: true,
      enabled: true,
      anonymousActionType: 'junction_login',
    },
  ),
);
