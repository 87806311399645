import React from 'react';
import Button1 from '../button/bgButton';
import Button2 from '../button/bgNoneButton';

interface modalProps {

    storageMessage: string;
    header: string;
    sendMessage : string;
    setIsModalOpen: () => void;
   
}

const ModalComponent = ({ header,sendMessage, storageMessage, setIsModalOpen } : modalProps)  => {

 


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50  ">
    <div className="absolute inset-0  bg-gray-500 opacity-75 h-auto  " />
        <div className="z-10 lg:w-[30%] w-[80%] bg-white rounded-lg shadow-xl p-4 lg:h-auto ">
            <div className="text-[16px] font-semibold  text-gray-500 pb-2 border-b">{header}</div>
                <div  className='mt-4 '>
                    <p className='lg:pt-6 pt-4 text-center font-semibold lg:text-xl text-[16px] '>{sendMessage}</p>
                    <p className='pt-4 text-center border-b  lg:text-lg text-[12px] lg:pb-8 mb-4 pb-4'>{storageMessage}</p>
                </div>
            <div className='flex justify-center mt-2 mb-2'> 
              
                <Button2 buttonText="닫기" onClick={() => setIsModalOpen()} style={{ width: '200px', height: '40px' }} />
             
            </div>
    </div>
</div>
  )
}

export default ModalComponent;