import React from 'react';

interface ButtonProps {
  buttonText: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

// export const Greeting = ({ name }: GreetingProps) => {

const cancleButton = ({ buttonText, onClick,style } : ButtonProps) => {
  return (
    <button onClick={onClick} 
            className='lg:w-[80px] w-[60px] h-[30px]  bg-[#0085FF] rounded-md  text-white   text-sm font-semibold hover:bg-blue-600 '
            style={style}
    >       
      		{buttonText}
    </button>
  );
}

export default cancleButton;