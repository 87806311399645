import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/footer';
import InfoBg from '../../Img/InfoBG.png';
import Logoex from '../../Img/logoex.png';
import Logo1 from '../../Img/logoex1.png';
import Logo2 from '../../Img/logoex2.png';
import Logo3 from '../../Img/logoex3.png';
import Logo4 from '../../Img/logoex4.png';
import Logo6 from '../../Img/logoex6.png';
import Logo7 from '../../Img/logoex7.png';
import Sign from '../../Img/sign.png';
import IMG1 from '../../Img/2022.png';
import IMG2 from '../../Img/2023.png';
import IMG3 from '../../Img/2024.png';
import IMG4 from '../../Img/2025.png';


import { FaAngleRight,FaPhone,FaRegClock } from "react-icons/fa6";

const InfoPage = () => {
    // const navigate = useNavigate();
    const [isImageLoaded, setImageLoaded] = useState(false);
  

    return (
        <>
        <div>
            <div className='w-full lg:pt-0 pt-[75px] '>
                <img src={InfoBg}
                    className='lg:w-full'  
                    onLoad={() => setImageLoaded(true)}
                    style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }} />
            </div>
            {isImageLoaded && (
            <>
            <div id='info1'className=''> 
            <div className='flex justify-center lg:mt-[160px] mt-[41px] '>
                    <div className='w-[70%] '>
                        <div className='lg:text-[98px] text-[26px]  font-black tracking-widest  lg:h-[120px]'>회사</div>
                        <div className='border border-[#d9d9d9]'> </div>
                        <div className='lg:text-[98px] text-[26px]  font-black tracking-widest '>소개</div>
                    </div>
                </div>   
                <div className='flex justify-center mt-[84px]'>
                    <img src={Logoex} className='lg:w-auto w-[169px] lg:h-auto h-[56px]'/>  
                    <div className='lg:pl-[32px] pl-[12px]'>
                        <h1 className='lg:text-[45px] text-[13px] font-medium'>(주)정션메드</h1>
                        <p className='lg:text-[30px] text-[8px] lg:pt-[28px] pt-2'> 가족과 연결해주는 통로의 의미의 (Junction)과 
                        </p>
                        <p className='lg:text-[30px] text-[8px]'> 
                            건강,의료의 의미의 Med를 결합해 만든 사 명
                        </p>
                    </div>
                </div>
                <div className='lg:mt-[160px] mt-[41px]'>
                    <div className='flex justify-center w-full'>
                        <div className='flex items-center'>
                            <img src={Logo1} className='lg:h-auto h-[13px] ' />
                            <div className='lg:text-[28px] text-[8px] lg:pl-[28px] lg:pr-[22px] pr-2 pl-2'>자연,성장,생명</div>
                        </div>
                        <div className='flex items-center '>
                            <img src={Logo2} className='lg:h-auto h-[13px] ' />
                            <p className='lg:text-[28px] text-[8px] lg:pl-[28px] lg:pr-[22px] pr-2 pl-2'>평온과 안정</p>
                        </div>
                        <div className='flex items-center '>
                            <img src={Logo3} className='lg:h-auto h-[13px] '/>
                            <p className='lg:text-[28px] text-[8px] lg:pl-[28px] lg:pr-[22px] pr-2 pl-2'>의료</p>
                        </div>
                        <div className='flex items-center '>
                            <img src={Logo4}className='lg:h-auto h-[13px] ' />
                            <p className='lg:text-[28px] text-[8px] lg:pl-[28px] lg:pr-[22px] pr-2 pl-2'>의지</p>
                        </div>
                    </div>
                    <div className='flex justify-center lg:mt-[109px] mt-[41px]'>
                        <div className='lg:pr-[100px] pr-[32px]'>
                            <p className='lg:text-[28px] text-[8px]'>심볼형</p>
                            <img src={Logo6} className='pt-[40px] lg:w-auto w-[55px]'/>
                        </div>
                        <div>
                            <p className='lg:text-[28px] text-[8px]'>가로형</p>
                            <img src={Logo7} className='pt-[40px] lg:w-auto w-[120px]'/>
                        </div>
                    </div>

                </div>
            </div>
            <div id='info2' className=''>
                <div className='flex justify-center lg:mt-[160px] mt-[72px] '>
                    <div className='w-[70%] '>
                        <div className='lg:text-[98px] text-[28px] font-black tracking-widest lg:h-[120px]'>대표자</div>
                        <div className='border border-[#d9d9d9]'> </div>
                        <div className='lg:text-[98px] text-[28px] font-black tracking-widest lg:h-[100px]'>인사말</div>
                        <div className=' '>
                            <h1 className='lg:text-[54px] text-[14px] text-[#0085FF] text-right font-bold'>“몸은 떨어져 있어도 <br/>마음은 항상 함께하는,케어,봄”</h1>
                        </div>  
                    </div>
                    
                </div>
                <div className=' flex justify-center'>
                    <div className='lg:text-[32px] text-[10px] w-[70%] text-right'>
                        <p className='lg:pt-[71px] pt-[23px]'>코로나-19로, 요양원에 계시던 할머니와의 면회가 전면 금지된 적이 있었습니다. 할머니의 간단한 건강 상태라도 알고 싶다는 생각을 시작으로 우리는 '케어,봄'을 탄생시켰습니다.</p>
                        <p className='lg:pt-[53px] pt-[14px]'>케어,봄은 디지털 헬스케어 플랫폼으로, 누구나 언제 어디서든 가족과의 연결을 유지하고 건강관리를 함께 할 수 있도록 합니다. 가족이 어디서든 우리의 마음과 함께하고 있다는 것에 안심할 수 있도록, 몸은 물론 마음까지 함께하는 유대감을 전합니다.</p>
                        <p className='lg:pt-[53px]  pt-[14px]'>우리는 어린 시절부터 배운 도덕과 효를 바탕으로, 가족과의 육체적인 거리를 떠나 정신적으로도 연결되어 있다는 것을 깨달았습니다. 이러한 가치를 바탕으로 '케어,봄'은 모든 사람들에게 가족을 돌볼 수 있는 사회를 만들기 위해 더 많은 노력을 기울이고 있습니다.</p>
                        <p className='lg:pt-[53px]  pt-[14px]'>초고령화 사회로 진입하는 우리나라에서, 우리는 더 많은 사람들이 가족과의 유대감을 느낄 수 있는 사회를 만들기 위해 노력합니다. '케어,봄'은 이러한 노력의 일부로, 마음과 건강을 함께 케어하는 서비스를 제공하고 있습니다. 함께 우리 사회를 따뜻하고 풍요롭게 만들어가는 여정에 함께해 주세요.</p>
                    </div>
                
                </div>
                <div className='flex justify-center '>
                    <div className='flex justify-end w-[70%]'>
                        <img src={Sign} className='lg:w-auto w-[72px]' />
                    </div>
                </div>
                
            </div>
            <div id='info3'>
                <div className='flex justify-center lg:mt-[160px] mt-[100px] lg:mb-[160px] mb-[100px]'>
                    <div className='w-[70%] '>
                        <div className='lg:text-[98px] text-[26px]  font-black tracking-widest lg:h-[120px]'>회사</div>
                        <div className='border border-[#d9d9d9]'> </div>
                        <div className='lg:text-[98px] text-[26px] font-black tracking-widest lg:h-[100px]'>연혁</div>                
                    </div>
                </div>
            </div>
            <div className=' flex justify-center'>
                <div className='w-[70%] lg:flex '>
                    <div className='lg:border-r-4 border-dashed lg:w-[50%] '> 
                       <div className='border-b-4 border-[#0085FF] flex lg:mt-20 lg:text-[48px] text-[22px] font-bold relative rounded'>
                        2020-2021
                        <div className='lg:w-[40px] lg:h-[40px] w-[20px] h-[20px] bg-[#0085FF] rounded-full absolute right-0 lg:top-12 top-6 z-2'></div>
                       </div>
                        <div className='lg:text-[22px] text-[12px] pt-[42px] pr-2 '>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>09 </strong> 성심중앙병원 M.O.U / TG Soft M.O.U</p>
                            <p className='pt-[12px] lg:pl-12 pl-8'>예비창업패키지 선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>10</strong> x-tech 창업경진대회 우수상 수상</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>01</strong> 정션메드 공식 블로그 오픈</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>해동 주니어 스타트업 프로그램 수상</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>02</strong>여성벤처기업 인증</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>03</strong>해동 주니어 스타트업 프로그램 수상</p>
                            <p className='pt-[12px] lg:pl-10 pl-7'>서울대 캠퍼스타운 창업 HERE-RO 사무실 입주</p>
                            <p className='pt-[12px] lg:pl-10 pl-7'>서울 청년창업사관학교 11기 선정</p>
                            <p className='pt-[12px] lg:pl-10 pl-7'>정션메드 홈페이지 구축</p>
                            <p className='pt-[12px] lg:pl-10 pl-7'>예비창업패키지 비대면 분야 최우수 평가 및 우수사례 선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>04</strong> 기업부설연구소 설립</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>강원형 뉴딜 아이디어 공모전 수상</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>강원창조혁신센터 협약</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>06-07</strong> 뉴비즈 스타트 M.O.U/ 한테크 M.O.U</p>
                            <p className='pt-[12px] lg:pl-[73px] pl-12'>(주)에어핏 M.O.U /왓위케어 M.O.U</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>07</strong>  창업기업 인증</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>강원창조경제혁신센터 사업화 지원금 2차 지원 확정</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>강원모바일헬스케어지원센터 BI 입주기관 선정</p>
                            <p className='pt-[12px] '><strong className='pr-[12px]'>08</strong> (주)마인드허브 M.O.U</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>강원국가혁신클러스터 사업화신속지원(INNO-TRACK) 선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>09</strong>강원창조경제혁신센터 2021 투자경진대회 데모데이 장려상 수상</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>SNU 동서 스타트업 프로듀스 34 마케팅 은상 수상</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>11</strong> 주식회사 헬리오네이처 M.O.U</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>주식회사 원스글로벌 M.O.U</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>강원창조혁신센터 우수기업 선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>12</strong>  예비창업패키지 최우수 수행기업 선정</p>
                            <p className='pt-[12px] lg:pl-11 pl-7 '>스마트도시협회 정회원 가입</p>
                        </div>
                       <div className=' lg:flex  items-center lg:mt-48 mt-12 hidden '>
                            <img src={IMG2} className='pr-10 '/>
                        </div>
                        <div className='border-b-4 border-[#0085FF]  lg:flex  mt-20  lg:text-[48px] text-[22px] font-bold relative rounded hidden'>
                        2023
                        <div className='lg:w-[40px] lg:h-[40px] w-[20px] h-[20px] bg-[#0085FF] rounded-full absolute right-0 lg:top-12 top-6 z-2'></div>
                       </div>
                       <div className='lg:text-[22px] text-[12px] pt-[42px] pr-2 lg:block hidden '>
                       <p className='pt-[12px]'><strong className='pr-[12px]'>01</strong> 벤처기업 인증</p>
                        <p className='pt-[12px]'><strong className='pr-[12px] '>02</strong> 에스큐빅 엔젤스 후속 Seed 투자</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>03</strong>시니어 벚꽃케어 페스티벌(in 진해군항제)주관/주최</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>04</strong> 비대면 스타트업 육성사업 선정</p>
                        <p className='pt-[12px] lg:pl-11 pl-7'>창업성장기술개발사업 디딤돌 선정</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>05</strong>  신보STEP-UP 도전프로그램 선정</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>06</strong>  AI 스타트업 창업경진대회 대상 수상</p>
                        <p className='pt-[12px]  lg:pl-11 pl-7'>(주)브레디헬스케어 MOU</p>
                        <p className='pt-[12px]  lg:pl-11 pl-7'>G_IN LEVEL 선정</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>07</strong> G_IN LEVEL 4기 프로젝트 선정</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>08</strong>(주)g케어 프로젝트 협약</p>
                        <p className='pt-[12px]  lg:pl-11 pl-7'>X위드커뮨협동조합</p>
                        <p className='pt-[12px]  lg:pl-11 pl-7'>X두루바른 사회적 협동 조합</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>09</strong> 시니어 헬스 케어 세미나 주최</p>
                        <p className='pt-[12px]  lg:pl-11 pl-7'>원주노인종합복지관 MOU</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>10</strong>G케어 실증 예비교육 진행</p>
                        <p className='pt-[12px]  lg:pl-11 pl-7'>그레이스케일 MOU</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>11</strong>한국사회적기업진흥원장상 수상</p>
                       </div>
                       <div className=' lg:flex justify-center items-center lg:mt-48 mt-20 hidden'>
                            <img src={IMG4} className='lg:pb-[200px] pr-10'/>
                        </div>

                    </div> 
                    <div className=' lg:w-[50%] h-auto lg:mt-20'>
                        <div className=' lg:flex justify-center items-center mt-48 hidden'>
                            <img src={IMG1} className='pb-[700px] pl-10'/>
                        </div>
                        <div className='border-b-4 border-[#0085FF]  flex justify-end mt-20  lg:text-[48px] text-[22px] font-bold relative rounded lg:pl-10'>
                        2022
                        <div className='lg:w-[40px] lg:h-[40px] w-[20px] h-[20px] bg-[#0085FF] rounded-full absolute left-0 lg:top-12 top-6 z-2'></div>
                       </div>
                       <div className='lg:text-[22px] text-[12px] pt-[42px] lg:pl-14'>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>02</strong>  주식회사 정션메드 법인 전환</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>사회적기업가 육성사업 선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>03</strong> 소셜벤처기업 판정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>04</strong> 신보 NEST 11기 선정</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>(주)마이허브앤헬스케어 M.O.U</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>05</strong> (주)쿡플레이 M.O.U</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>06</strong> 콘텐츠 초기 스타트업 사업화 지원 (소셜벤처) 선정</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>D.N.A 제품고도화 사업 선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>07</strong>원주 의료사협 M.O.U</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>B-스타트업 챌린지 TOP 10선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>09</strong>한양대 린벨류업 선정</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>보건복지부형 예비사회적기업 인증</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>충북기술지주펀딩, 벤처박스 Seed 투자</p>
                       </div>
                       <div className='border-b-4 border-[#0085FF]  lg:hidden  mt-20  lg:text-[48px] text-[22px] font-bold relative rounded '>
                        2023
                        <div className='lg:w-[40px] lg:h-[40px] w-[20px] h-[20px] bg-[#0085FF] rounded-full absolute right-0 lg:top-12 top-6 z-2'></div>
                       </div>
                       <div className='lg:text-[22px] text-[12px] pt-[42px] pr-2 lg:hidden '>
                       <p className='pt-[12px]'><strong className='pr-[12px]'>01</strong> 벤처기업 인증</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>02</strong> 에스큐빅 엔젤스 후속 Seed 투자</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>03</strong>시니어 벚꽃케어 페스티벌(in 진해군항제)주관/주최</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>04</strong> 비대면 스타트업 육성사업 선정</p>
                        <p className='pt-[12px] lg:pl-11 pl-7'>창업성장기술개발사업 디딤돌 선정</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>05</strong>신보STEP-UP 도전프로그램 선정</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>06</strong>AI 스타트업 창업경진대회 대상 수상</p>
                        <p className='pt-[12px] lg:pl-11 pl-7'>(주)브레디헬스케어 MOU</p>
                        <p className='pt-[12px] lg:pl-11 pl-7'>G_IN LEVEL 선정</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>07</strong>G_IN LEVEL 4기 프로젝트 선정</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>08</strong>(주)g케어 프로젝트 협약</p>
                        <p className='pt-[12px] lg:pl-11 pl-7'>X위드커뮨협동조합</p>
                        <p className='pt-[12px] lg:pl-11 pl-7'>X두루바른 사회적 협동 조합</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>09</strong>시니어 헬스 케어 세미나 주최</p>
                        <p className='pt-[12px] lg:pl-11 pl-7'>원주노인종합복지관 MOU</p>
                        <p className='pt-[12px] '><strong className='pr-[12px]'>10</strong>G케어 실증 예비교육 진행</p>
                        <p className='pt-[12px] lg:pl-11 pl-7'>그레이스케일 MOU</p>
                        <p className='pt-[12px]'><strong className='pr-[12px]'>11</strong>한국사회적기업진흥원장상 수상</p>
                       </div>
                       <div className=' lg:flex justify-center items-center mt-48 hidden'>
                            <img src={IMG3} className='pb-[300px] pl-10'/>
                        </div>
                        <div className='border-b-4 border-[#0085FF]   flex justify-end mt-20  lg:text-[48px] text-[22px] font-bold relative rounded lg:pl-10  '>
                        2024
                        <div className='lg:w-[40px] lg:h-[40px] w-[20px] h-[20px] bg-[#0085FF] rounded-full absolute left-0 lg:top-12 top-6 z-2'></div>
                       </div>
                       <div className='lg:text-[22px] text-[12px] pt-[42px] pr-2 lg:pb-0 pb-10 lg:pl-10 mb-28'>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>01</strong>   투자기술평가평가등급 TI-3 획득</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>기업부설연구소 이전 ( 마포구 : 마스터즈 타워 )</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>02</strong> 광주시민체감형AI바우처 선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>03</strong> 광주 지사 설립</p>
                            <p className='pt-[12px]'><strong className='pr-[12px] '>04</strong>  광주 광산구더불어락 노인복지관 MOU</p>
                            <p className='pt-[12px] lg:pl-8 pl-4 '><strong className='pr-[12px]'></strong>  서원대 MOU</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>05</strong> 광산구더불어락노인복지관 케어봄 서비스 강의</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'> /강원대 MOU / 더존 투자 확정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>06</strong> 원주시노인종합복지관 케어봄 서비스 강의</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>한베문화교류센터(베트남) MOU</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>원주노인종합복지관 실증 돌입</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>한국농산어촌네트워크 X 봉명고라데이영농조합 MOU</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>07</strong>'2024 보령 청소년 진로 박람회' 참가</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>'2024 보령 청소년 진로박람회' 참가</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>'CES 2025 광주공동관' 참가기업 선정</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>08</strong>TIPS 선정</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>광주 빛고을건강센터에서 AI 헬스케어 실증 실행</p>
                            <p className='pt-[12px] lg:pl-11 pl-7'>G-임(林)노베이션 아카데미 강연</p>
                            <p className='pt-[12px]'><strong className='pr-[12px]'>09</strong>광주광역시와 AI 기업 MOU</p>
                       </div>

                    </div>

                </div>
                
            </div>
            </>
            )}
            
            
          
        </div>
        {isImageLoaded && (
        <Footer />
        )}
        </>
    );
};

export default InfoPage;