import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/footer';
import Modal from '../../common/modal/warningComponent';
import MediaBg from '../../Img/mediaBG.png';
import { fetchUserBoardList } from '../../lib/api/adminApi';

interface ListItem {
  TITLE: string;
  WRITE_DT: string;
  WRITE_SEQ: number;
  NOTICE_YN: string;
  TITLE_IMG_PATH: string;
  START_DT: string;
  END_DT: string;
  SEQ: number;
  BOARD_GBN: string;
  CONTENTS: string;
}

const NewsPage = () => {
  const [isImageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();
  const [boardList, setBoardList] = useState<ListItem[]>([]);
  const [showReady, setShowReady] = useState<boolean>(false);

  const getBoardList = async () => {
    try {
      const inputData = {
        in_gbn: 'ALL',
        in_seq: '',
        in_board_gbn: '뉴스',
        in_keyword: '',
      };

      console.log('save input:' + JSON.stringify(inputData));

      const { data } = await fetchUserBoardList(inputData);

      console.log('B:' + JSON.stringify(data));

      if (data.success) {
        setBoardList(data.data);
        localStorage.setItem('setBoardList', JSON.stringify(data));
      } else {
        // Handle failure
      }
    } catch (e) {
      // Handle error
    }
  };

  useEffect(() => {
    getBoardList();
  }, []);

  const ViewClick = (seq: any) => {
    navigate('/user-editorNView', { state: { seq: seq } });
  };

  const Noready = () => {
    setShowReady(true);
  };

  const extractImageUrl = (contents: string): string => {
    const imageUrlRegex = /src="(https?:\/\/.*?\.(?:png|jpg|jpeg|gif))"/i;
    const match = contents.match(imageUrlRegex);
    return match ? match[1] : '';
  };

  return (
    <div className="">
      <img
        src={MediaBg}
        className="w-full lg:pt-0 pt-[76px]"
        onLoad={() => setImageLoaded(true)}
        style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center"></div>
        {isImageLoaded && (
          <div className="mt-8 flow-root mb-20 lg:ml-[10%] lg:mr-[10%]">
            <div className="lg:mt-[169px] mt-10">
              <div className="border-b-4 border-[#0085FF]">
                <h1 className="lg:text-[98px] text-[26px] font-bold">새소식</h1>
              </div>
            </div>
            <div className="pl-2">
              <div className="">
                {boardList.length > 0 && (
                  <div className="grid lg:grid-cols-3 gap-4 mt-10">
                    {boardList.map((list, index) => (
                      <div key={list.SEQ} className="pl-6 pr-6">
                        {list.CONTENTS && (
                          <img
                            src={extractImageUrl(list.CONTENTS)}
                            className="rounded-lg cursor-pointer lg:h-[250px] lg:w-[400px]"
                            onClick={() => ViewClick(list.SEQ)}
                          />
                        )}
                        <div
                          className="pl-3 font-semibold pt-3 flex text-lg sm:text-sm"
                          onClick={() => ViewClick(list.SEQ)}
                        >
                          {list.TITLE}
                          {index === 0 && (
                            <div className="w-5 h-5 rounded-full bg-red-400 text-white text-sm flex justify-center items-center ml-2">
                              N
                            </div>
                          )}
                        </div>
                        <div className="pl-3 pt-1 text-sm text-gray-500">
                          {list.WRITE_DT}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {isImageLoaded && <Footer />}
    </div>
  );
};

export default NewsPage;
