import { useState, useEffect,useCallback, } from 'react';
import{ useLocation,useNavigate } from 'react-router-dom';
import { AiOutlineCaretUp,AiOutlineCaretDown } from "react-icons/ai";
import parse from 'html-react-parser'
import { fetchAdminBoardModify,fetchUserBoardList } from '../../../lib/api/adminApi';
import './editor.css';

interface ListItem {

    TITLE: string;
    CONTENTS: string;
    BOARD_GBN:string;
    WRITE_DT: string;
    BEFORE_TITLE:string
    BEFORE_SEQ:number;
    AFTER_TITLE:string;
    AFTER_SEQ:number;
    
}

const EditorViewPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [seq, setSeq] = useState(location?.state.seq ?? ''); 
    const [ boardList, setBoardList ] = useState<ListItem[]>([]);

    const getBoardList = useCallback(async () => {
        console.log('seq:'+seq)
        try {
            const inputData = {
                in_gbn: 'LIST_VIEW',
                in_seq: seq,
                in_board_gbn: '뉴스',
                in_keyword: '',
            };
    
            const { data } = await fetchUserBoardList(inputData);
    
            if (data.success) {
                setBoardList(data.data);
            } else {
                // 실패 처리
            }
        } catch (error) {
            // 에러 처리
        }
    }, [seq]);

    const Gobefore = (seq:number) => {
        setSeq(seq.toString());
    
    }

    const Goafter = (seq:number) => {
        setSeq(seq.toString());
    }

    const Golist = () => {
      window.location.href = '/mediaNews'
    
    }
    // useEffect(()=>{
    //     getBoardList()
    // },[])
    useEffect(() => {
        if (seq) {
            getBoardList();
        }
    }, [seq, getBoardList]);

    return (
        <div className=''>
        {boardList.map((list) => (
            <div className='flex justify-center w-full pt-[200px] pb-[70px] '>
                <div className=' w-[80%] ml-14 mr-14'> 
                    <div className=' border-b pt-10  overflow-scroll '>
                        <div className='border-b-2 border-[#0085FF] pb-2'>
                            <div className='flex justify-center pb-2 border-[#2999FF] '>
                                <div className='text-xl font-bold pt-1 lg:flex'><p className='pr-2'>[{list.BOARD_GBN}]</p>{list.TITLE}</div>
                            </div>
                            <div className='flex  lg:pl-2 text-sm text-gray-500 '>
                            <div className='border  rounded-lg bg-[#E0F4FF] mr-4 text-xs flex items-center py-1 px-2 '>{list.BOARD_GBN}</div>
                            <p className='pr-1 pt-1'>작성일자: </p>  <p className='pt-1'>{list.WRITE_DT}</p>
                            </div>
                        </div>
                        
                        <div>
                            {/* 이미지를 포함한 HTML을 파싱하여 이미지 추출 */}
                            {list.CONTENTS && (
                                <>
                                <div className='image-container ' >
                                    <div dangerouslySetInnerHTML={{ __html: list.CONTENTS }} 
                                    className='text-[20px] text-gray-600 pl-4 pt-6 pb-14 overflow-scroll'/>
                                </div>                      
                                </>
                            )}
                        </div>
                    </div> 
                    {(list.BEFORE_SEQ || list.AFTER_SEQ) && (
                    <div className='pt-4 text-gray-500 cursor-pointer border-b pb-2'>
                    {list.BEFORE_SEQ && (
                        <div className='flex'> <AiOutlineCaretUp className='mr-2 pt-1'/>이전 :   
                            <div className='pl-4 hover:underline hover:font-bold overflow-hidden lg:max-w-full max-w-[200px] truncate ' onClick={() => Gobefore(list.BEFORE_SEQ)}>{list.BEFORE_TITLE}</div>
                        </div>
                    )}   
                     {list.AFTER_SEQ && (
                        <div className='flex pt-2'><AiOutlineCaretDown className='mr-2 pt-1'/>다음 :   
                            <div className='pl-4 hover:underline hover:font-bold overflow-hidden lg:max-w-full max-w-[200px] truncate ' onClick={() => Goafter(list.AFTER_SEQ)}>{list.AFTER_TITLE}</div>
                        </div>
                     )}
                    </div>     
                    )}         
                    <div className='flex justify-end mt-4'>
                            <button className='text-white mt-2 rounded-md py-2 px-2 bg-[#2999FF] font-semibold hover:bg-blue-500' onClick={Golist}>목록으로</button>
                        </div>
                </div>
                    
            </div>
        ))}
    </div>
    )
}

export default EditorViewPage;