import { authClient, unAuthClient } from "./axiosBase";

interface IAuthLoginInput {
    in_gbn: string;
    userId: string;
    password: string;
}
 
interface ICreateUserInput {
    userId: string;
    password: string;
    userNm: string;
    phone: string;
}

interface IModifyUserInput {
    gbn: string;
    userId: string;
    rank: string;
}

interface IAuthLoginOutput {
    success: boolean; // * 성공 여부
    msg: string; // * 메세지
    token: string; // * 토큰
    id: string; // * 회원 아이디
    is_admin: string; // * 관리자 여부
    user_nm: string; // * 회원 이름
    phone: string;  // * 회원 전화번호
}

// export const fetchAuthLogin_1 = async ({ userId,  password }: IAuthLoginInput): Promise<IAuthLoginOutput> => {
//     const { data } = await unAuthClient.post<IAuthLoginOutput>('/login', {
//       in_id: userId,
//       in_password: password,
//     });
  
//     return data;
// };

// 로그인
export const fetchAuthLogin = async ({ in_gbn, in_user_id, in_user_pw }: { in_gbn: string; in_user_id: string; in_user_pw: string }) => await unAuthClient.post('admin/login', {
    in_gbn,
    in_user_id,
    in_user_pw,
});
// 회원 생성
export const fetchCreateUser = async ({ userId,  password, userNm, phone }: ICreateUserInput) => await authClient.post('/admin/userCreate', {
    in_id: userId,
    in_password: password,    
    in_user_nm: userNm,   
    in_phone: phone,   
});

// 회원정보 수정
export const fetchModiftUser = async ({ gbn, userId, rank }: IModifyUserInput) => await authClient.post('/admin/userModify', {
    in_gbn: gbn,
    in_id: userId,       
    in_rank: rank, 
});