import Img1 from '../../../Img/1950/1950.png';
import Img2 from '../../../Img/1950/1960.png';
import Img3 from '../../../Img/1950/1970.png';
import Img4 from '../../../Img/1950/1980.png';
import Img5 from '../../../Img/1950/1990.png';
import Img6 from '../../../Img/1950/2000.png';
import Img7 from '../../../Img/1950/2020.png';

const aiComponent  = () => {


  

  return (
    <div className='lg:mb-[50px] mb-2 '>
      <div>
        <h1 className='lg:text-[42px] text-[24px] text-[#0085FF] font-extrabold lg:pl-[80px] pl-[26px] pt-[20px] '> AI가 말하는 건강기록 </h1>
      </div>
      <div className='mb-[50px] overflow-x-scroll   flex lg:ml-[70px]  ml-[26px] mt-[27px] pb-10  lg:hover:pb-10 lg:hover:pl-[30px] lg:hover:pt-10 hover:pl-4 hover:pt-4'>
      <div className='flex-none lg:w-[513px] lg:h-[359px] w-[200px] h-[150px] lg:mr-[52px] mr-[16px] relative overflow-hidden hover:rounded-xl hover:scale-110'>
        <img src={Img1} alt="" className='cursor-pointer   '/>
        <h1 className='lg:text-[70px] text-[26px] font-bold absolute lg:bottom-[20px] bottom-6 left-4 text-white '>1950</h1>
        <div className='lg:text-[24px] text-[10px] lg:pt-[20px] mb-2 pb-2 pt-2 '>6.25전쟁이후 질병예방과 기호의료서비스 시작 </div>
      </div>
      <div className='flex-none lg:w-[513px] lg:h-[359px] w-[200px] h-[150px] lg:mr-[52px] mr-[16px] relative overflow-hidden hover:rounded-xl  hover:scale-110'>
        <img src={Img2} alt="" className='cursor-pointer '/>
        <h1 className='lg:text-[70px] text-[26px] font-bold absolute lg:bottom-[20px] bottom-6 left-4 text-white '>1960</h1>
        <div className='lg:text-[24px] text-[10px] lg:pt-[20px] mb-2 pb-2 pt-2'>보건의료 시스템 강화, 예방접종 프로그램 시작 </div>
      </div>
      <div className='flex-none lg:w-[513px] lg:h-[359px] w-[200px] h-[150px] lg:mr-[52px] mr-[16px] relative overflow-hidden hover:rounded-xl hover:scale-110'>
        <img src={Img3} alt="" className='cursor-pointer    '/>
        <h1 className='lg:text-[70px] text-[26px] font-bold absolute lg:bottom-[20px] bottom-6 left-4 text-white '>1970</h1>
        <div className='lg:text-[24px] text-[10px] lg:pt-[20px] mb-2 pb-2 pt-2'>국민건강보험의 시작</div>
      </div>
      <div className='flex-none lg:w-[513px] lg:h-[359px] w-[200px] h-[150px] lg:mr-[52px] mr-[16px] relative overflow-hidden hover:rounded-xl hover:scale-110'>
        <img src={Img4} alt="" className='cursor-pointer   '/>
        <h1 className='lg:text-[70px] text-[26px] font-bold absolute lg:bottom-[20px] bottom-6 left-4 text-white '>1980</h1>
        <div className='lg:text-[24px] text-[10px] lg:pt-[20px] mb-2 pb-2 '>성인병 예방</div>
      </div>
      <div className='flex-none lg:w-[513px] lg:h-[359px] w-[200px] h-[150px] lg:mr-[52px] mr-[16px] relative overflow-hidden hover:rounded-xl hover:scale-110'>
        <img src={Img5} alt="" className='cursor-pointer  '/>
        <h1 className='lg:text-[70px] text-[26px] font-bold absolute lg:bottom-[20px] bottom-6 left-4 text-white '>1990</h1>
        <div className='lg:text-[24px] text-[10px] lg:pt-[20px] mb-2 pb-2 pt-2'>건강식품의 시장 성장</div>
      </div>
      <div className='flex-none lg:w-[513px] lg:h-[359px] w-[200px] h-[150px] lg:mr-[52px] mr-[16px] relative overflow-hidden hover:rounded-xl hover:scale-110'>
        <img src={Img6} alt="" className='cursor-pointer  '/>
        <h1 className='lg:text-[70px] text-[26px] font-bold absolute lg:bottom-[20px] bottom-6 left-4 text-white '>2000</h1>
        <div className='lg:text-[24px] text-[10px] lg:pt-[20px] mb-2 pb-2 pt-2'>전자의료기록 시스템</div>
      </div>
      <div className='flex-none lg:w-[513px] lg:h-[359px] w-[200px] h-[150px] lg:mr-[52px] mr-[16px] relative overflow-hidden hover:rounded-xl hover:scale-110'>
        <img src={Img7} alt="" className='cursor-pointer   '/>
        <h1 className='lg:text-[70px] text-[26px] font-bold absolute lg:bottom-[20px] bottom-6 left-4 text-white '>2010</h1>
        <div className='lg:text-[24px] text-[10px] lg:pt-[20px] mb-2 pb-2 pt-2'>의료기술 발달로 수술 및 치료법 개선</div>
      </div>
    </div>
      
    </div>
  );
};

export default aiComponent;