import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/footer';
import ServiceBg2 from '../../Img/serviceBG2.png';
import Applogo1 from '../../Img/applogo1.png';
import Applogo2 from '../../Img/applogo2.png';
import Section1 from '../../Img/serviceinfo1.png';
import Section2 from '../../Img/serviceinfo2.png';
import Section3 from '../../Img/serviceinfo3.png';
import Section4 from '../../Img/serviceinfo4.png';
import Section5 from '../../Img/serviceinfo5.png';
import Section6 from '../../Img/serviceinfo6.png';
import Mphone from '../../Img/service_img.png';
import './service.css';

const ServiceInfoPage = () => {
  const [isImageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();

  const handleButtonClick = (): void => {
    navigate('/en-serviceinfo');
  };

  const Android = () => {
    window.location.href = 'https://play.google.com/store/search?q=케어봄&c=apps';
  };

  const Appstore = () => {
    window.location.href = 'https://apps.apple.com/kr/app/케어봄-carebom/id1671401959';
  };

  return (
    <>
      <div>
        <img
          src={ServiceBg2}
          className='w-full lg:pt-0 pt-[76px]'
          onLoad={() => setImageLoaded(true)}
          style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }}
        />
        {isImageLoaded && (
          <>
            <div className='lg:h-[1080px]'>
              {/* ENG btn */}
              <div className='absolute lg:top-[8%] top-[10%] lg:right-8  right-2 z-10'>
                <button
                  className='bg-[#333] text-white w-[100px] h-[40px] rounded-lg font-bold cursor-pointer'
                  onClick={handleButtonClick}
                >
                  ENG
                </button>
              </div>
              {/* ENG btn */}
              <div className='flex'>
                <div className='w-full justify-left lg:ml-[123px] ml-[40px] lg:mt-[123px] mt-[60px]'>
                  <div className='font'>
                    <div id='font' className='lg:text-[80px] text-[16px] sm:text-[30px]'>
                      가족을 생각하는
                    </div>
                    <div id='font' className='lg:text-[80px] text-[16px] sm:text-[30px]'>
                      마음으로 연결해주는
                    </div>
                    <div>
                      <span id='font1' className='lg:pr-6 pr-2 lg:text-[80px] text-[16px] sm:text-[30px]'>
                        케어봄
                      </span>
                      <span id='font' className='lg:text-[80px] text-[16px] sm:text-[30px]'>
                        서비스
                      </span>
                    </div>
                  </div>

                  <div className='lg:mt-[174px] mt-[32px]'>
                    <div className='lg:text-[28px] text-[6px] font-bold sm:text-[16px]'>
                      개인용 APP 다운로드
                    </div>
                    <div className='flex mt-[12px]'>
                      <img
                        src={Applogo1}
                        className='cursor-pointer lg:w-auto lg:h-[auto] w-[20px] h-[20px] sm:w-[30px] sm:h-[30px]'
                        onClick={Appstore}
                      />
                      <img
                        src={Applogo2}
                        className='cursor-pointer lg:w-auto lg:h-[auto] w-[20px] h-[20px] sm:w-[30px] sm:h-[30px]'
                        onClick={Android}
                      />
                    </div>
                  </div>
                  <div className='lg:text-[25px] text-[6px] text-[#0085FF] font-bold lg:mt-[23px] mt-2 sm:text-[9px]'>
                    * 기관용은 별도의 코드를 드립니다.
                  </div>
                </div>
                <div className='justify-end mt-[60px] mr-10 w-full '>
                  <img src={Mphone} className='lg:w-[90%]  w-[300px] mt-[120px]' />
                </div>
              </div>
            </div>
            <div className=''>
              <img src={Section1} className='w-full' />
              <img src={Section2} className='w-full' />
              <img src={Section3} className='w-full' />
              <img src={Section4} className='w-full' />
              <img src={Section5} className='w-full' />
              <img src={Section6} className='w-full' />
            </div>
          </>
        )}
      </div>
      {isImageLoaded && <Footer />}
    </>
  );
};

export default ServiceInfoPage;
