	import { useState,useEffect } from "react";
	import {  fetchAdminUserList, fetchAdminModifyUser } from '../../lib/api/adminApi'
	import {fetchCommSel} from '../../lib/api/commApi'
	import { useNavigate, } from 'react-router-dom';
	import AccessBtn from "../../common/button/bgButton";
	import SuccessModal from "../../common/modal/modalComponent";
	import RegisterPage from "../../common/modal/registerComponent";
	import UserModifyPage from "../../common/modal/userMComponent";
	
	import Select from 'react-select';



	interface ListItem {
		USER_ID: string;
		USER_NM: string;
		USER_RANK: string;
		HP_NO: string;
	

	}


	interface Inputs {
	userrank:string;
	chguserrank:boolean;
	}





	const AdminUserPage = ()  => {


		let navigate = useNavigate();



		const [ userList, setUserList ] = useState<ListItem[]>([]);
		const [ ShowSuccess, setShowSuccess] = useState(false); 
		const [ userId, setUserId ] = useState('')
		const [Selected, setSelected] = useState('');
		const [ShowUser, setShowUser] = useState(false);

		// const [ delSeq, setDelSeq ] = useState(0)
		// const [ Showdel, setShowdel] = useState(false); 
	
	
		const getUserList = async () => {

			const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
			const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
			

		try {
			
			const inputData= {
	
					gbn:'SEL', 
					data1:'',
					data2:'',
					data3:'',
					data4:'',
					data5:'',
					
	
				}
	
				console.log('save input:'+ JSON.stringify(inputData));
				
	
				
			const {data} = await fetchAdminUserList(inputData)
		
			console.log('User:'+ JSON.stringify(data))
	
			if(data.success){
			
			setUserList(data.data);
			localStorage.setItem('setUserList', JSON.stringify(data));
			// window.location.href = "/"
	
			}else{
			// alert('실패')
	
			}
		
		} catch (e) {
			// setError(e);
		}

		}

		
	
	
	
		useEffect(() => {
			getUserList();
		}, []);

		const addUser = () => {
			setShowSuccess(true);
		  };

		const UserClick = (userId: string) => {
			setShowUser(true);
			setUserId(userId);
			
		};

	


	return (
			<div className="px-4 sm:px-6 lg:px-8 lg:pt-[50px] h-[1028px] lg:ml-24 lg:mr-24">
				<div className="sm:flex sm:items-center border-b ">
					<div className="sm:flex-auto ">
						<div className="flex mt-[50px]  ">
							<h1 className='text-lg font-bold pt-2 pr-1 text-gray-800 '> 사용자 관리 </h1>
						</div>
					</div>
					<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
						<button
							type="button"
							onClick={addUser}
							className="block rounded-md bg-[#0085FF] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
							사용자등록
						</button>
					</div>
				</div>
				<div className="mt-8 flow-root ml-4 mr-4">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8  h-[800px]">
							<table className="min-w-full divide-y divide-gray-300  ">
								<thead className="">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left lg:text-sm  text-xs font-semibold text-gray-900 sm:pl-0"
										>
											아이디
										</th>
										<th scope="col" className="px-3 py-3.5 text-left lg:text-sm  text-xs  font-semibold text-gray-900">
											이름
										</th>
										<th scope="col" className="px-3 py-3.5 text-left lg:text-sm  text-xs font-semibold text-gray-900 w-auto">
											휴대폰 번호
										</th>
										<th scope="col" className="px-3 py-3.5 text-left lg:text-sm  text-xs font-semibold text-gray-900">
											직급
										</th>
										{/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											승인
										</th> */}
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200  ml-2 ">
									{userList.map((list) => (
										<tr key={list.USER_ID} className="hover:bg-gray-100 cursor-pointer  ">
											<td className="whitespace-nowrap py-4 pl-6  text-sm font-medium text-gray-900 sm:pl-0 "  onClick={() => UserClick(list.USER_ID)}>
												{list.USER_ID}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500" onClick={() => UserClick(list.USER_ID)}>{list.USER_NM}</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500" onClick={() => UserClick(list.USER_ID)}>{list.HP_NO}</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500" onClick={() => UserClick(list.USER_ID)}>
												{list.USER_RANK}
											{/* <select name="userrank" id="userrank" onChange={onChangeInfo} >
												<option value="" selected={list.ranking === ""}> 선택 </option>
												<option value="VVIP" selected={list.ranking === "VVIP"}>VVIP</option>
												<option value="VIP" selected={list.ranking === "VIP"}>VIP</option>
												<option value="Executive" selected={list.ranking === "Executive"}>Executive</option>
											</select> */}
											
											</td>
											{/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{<AccessBtn  buttonText="PW초기화" onClick={() => UserClick(list.USER_ID)}style={{ width: '70px', height: '30px' }}/>}
											</td>
											 */}
											{/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
											</td> */}	
										</tr>
									))}
								</tbody>
							</table>
							{ShowSuccess && (
								<RegisterPage
									setIsModalOpen={() => setShowSuccess(false)}
								
									
								/>
							)}
							{ShowUser && (
								<UserModifyPage
									setIsModalOpen={() => setShowUser(false)}
									USER_ID={userId}
								/>
							
							)}
						</div>
					</div>
				</div>
			</div>
		
	);
	};

	export default AdminUserPage;