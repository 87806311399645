import axios from 'axios';
import _ from 'lodash'

export const unAuthClient = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

export const authClient = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

authClient.interceptors.request.use(
    async function (config) {
      const token = localStorage.getItem('junction_login');
  
      if (!token) throw new Error('인증 관련하여 문제가 발생하였습니다.');
  
      const userToken = JSON.parse(token)?.token;
  
      if (!userToken) throw new Error('인증 관련하여 문제가 발생하였습니다.');
  
      if (config.headers) config.headers.Authorization = `Bearer ${userToken}`;
  
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );


authClient.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (!_.isEmpty(error.response)) {
			localStorage.removeItem('junction_login');
			window.location.href = '/';
		}
		return Promise.reject(error);
	},
); 