import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/footer';
import ServiceBg from '../../Img/serviceBg.png';
import Cami from '../../Img/cami.png';
import Rectangle from '../../Img/Rectangle.png';
import Service2 from '../../Img/service2section.png';
import Service3 from '../../Img/service3section.png';
import Service4 from '../../Img/service4section1.png';
import Service5 from '../../Img/service4section2.png';
import Service6 from '../../Img/service4section3.png';
import Plus from '../../Img/plus.png';

const ServicePage = () => {
  const [isImageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = (): void => {
    navigate('/');
  };

  return (
    <>
      <div className='w-full'>
        <img
          src={ServiceBg}
          className='w-full lg:pt-0 pt-[76px]'
          onLoad={() => setImageLoaded(true)}
          style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }}
        />
        {isImageLoaded && (
          <div>
            <div className='lg:flex lg:justify-center lg:pl-0 pl-10'>
              <div className='lg:mt-[117px] mt-[40px] font-extrabold leading-tight lg:240w-[55%]'>
                <div className='lg:text-[98px] text-[24px]'>안녕하세요</div>
                <div className='lg:text-[98px] text-[24px]'>
                  저는 <span className='text-[#0085FF]'>‘케미’ </span>라고 해요.
                </div>
              </div>
            </div>
            <div className='flex justify-center lg:mt-[100px] mt-[40px]'>
              <img src={Cami} className='lg:ml-0 pl-10 lg:mr-0 pr-10' decoding='async' loading='lazy' />
            </div>
            <div className='flex justify-center'>
              <div className='lg:w-[55%] pt-[70px] lg:pl-0 pl-10 lg:pr-0 pr-10'>
                <p className='lg:text-[28px] text-[12px]'>
                  정션메드의 대표 캐릭터.이름은{' '}
                  <strong className='lg:text-[32px] text-[20px]'>‘케미’</strong>로 곰을 의인화하였습니다. 곰 배에 있는 마크는
                  회사 주요 아이템인 헬스케어 커뮤니케이션 어플 ‘케어봄’의 마크입니다. ‘건강’은 언제나 걱정이라는 감정이
                  수반되는 단어이지만, 우리 주변에서 흔히 볼 수 있는 ‘곰돌이’라는 낯설지 않은 편안함으로 따뜻하게 함께
                  해주는 존재로 작은 위안이 되고자 합니다.
                </p>
                <p className='pt-[50px] lg:text-[28px] text-[12px]'>
                  누구와도 케미 넘치는 소통이 가능하다고 해서 케미(:케어하는곰(돌)이)입니다. 케어봄 심볼 쉐입과 비슷한
                  형상을 가진 볼수록 매력있는 케미 많은 사랑 부탁드립니다.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {isImageLoaded && (
        <>
          <div className='lg:mt-[350px] mt-20 relative w-full'>
            <div className='flex justify-center w-full'>
              <div className='lg:border-b-8 border-b-4 border-[#0085FF] w-full rounded-md'></div>
              <div className='lg:text-[80px] text-[20px] md:text-[70px] lg:pl-[70px] w-full lg:pr-[70px] flex justify-center text-[#0085FF] font-extrabold sm:text-[40px]'>
                '케어봄'
              </div>
              <div className='lg:border-b-8 border-b-4 border-[#0085FF] w-full rounded-md'></div>
            </div>
            <img src={Rectangle} className='w-full lg:h-[1387px]' decoding='async' loading='lazy' />
            <div className='flex justify-center'>
              <img src={Service2} className='absolute lg:top-64 top-14' decoding='async' loading='lazy' />
              <div className='absolute lg:top-[1100px] lg:text-[50px] text-center font-bold text-[10px] top-56 sm:top-[410px] sm:text-[18px]'>
                <div>시니어와 가족, 기관을</div>
                <div className='flex justify-center text-center'>
                  <p className='lg:pr-2 pr-1 text-[#0085FF] lg:text-[52px] text-[11px] sm:text-[18px]'>연결</p>해주는 서비스
                </div>
              </div>
              <div className='flex justify-center'>
                <img
                  src={Service3}
                  className='absolute lg:top-[1250px] lg:w-auto w-[154px] top-64 sm:top-[490px]'
                  decoding='async'
                  loading='lazy'
                />
              </div>
            </div>
          </div>

          <div className='lg:h-[520px] h-[200px] bg-[#f5fafd] lg:mt-[500px] mt-[80px] sm:h-[250px] lg:pl-6 lg:pr-6'>
            <div className='pt-[57px]'>
              <div className='lg:text-[38px] text-[8px] text-[#0085FF] font-extrabold flex justify-center sm:text-[20px]'>
                용어설명
              </div>
              <div className='flex justify-center lg:pt-[72px] pt-6'>
                <div className='lg:w-[405px] lg:h-[260px] w-[78px] h-[60px] sm:w-[150px] sm:h-[95px] bg-[#E0F4FF] rounded-lg lg:mr-[30px] mr-[7px] drop-shadow-md'>
                  <h1 className='lg:text-[40px] text-[8px] lg:pt-[32px] pt-[6px] lg:pl-[16px] pl-[3px] sm:text-[14px]'>PHR</h1>
                  <p className='lg:text-[25px] text-[6px] lg:pl-[16px] pl-[3px] sm:text-[11px]'>
                    (Personal Health Record)
                  </p>
                  <p className='lg:text-[25px] text-[6px] lg:pl-[16px] pl-[3px] sm:text-[11px]'> 개인 건강기록관리</p>
                </div>
                <div className='lg:w-[405px] lg:h-[260px] w-[78px] h-[60px] bg-[#E0F4FF] sm:w-[150px] sm:h-[95px] rounded-lg lg:mr-[30px] mr-[7px] drop-shadow-md'>
                  <h1 className='lg:text-[40px] text-[8px] lg:pt-[32px] lg:pl-[16px] pt-[6px] sm:text-[14px] pl-[3px]'>GHR</h1>
                  <p className='lg:text-[25px] text-[6px] lg:pl-[16px] sm:text-[12px]'>(Group Health Record)</p>
                  <p className='lg:text-[25px] text-[6px] lg:pl-[16px] sm:text-[12px]'> 공동 건강관리</p>
                </div>
                <div className='lg:w-[405px] lg:h-[260px] sm:w-[150px] sm:h-[95px] w-[78px] h-[60px] bg-[#E0F4FF] rounded-lg lg:mr-[30px] mr-[7px] drop-shadow-md pl-2'>
                  <h1 className='lg:text-[40px] text-[8px] lg:pt-[32px] lg:pl-[16px] pl-[3px] pt-[6px] sm:text-[16px]'>UHC</h1>
                  <p className='lg:text-[25px] text-[6px] sm:text-[12px]'>(Universal Health Coverage) </p>
                  <p className='lg:text-[25px] text-[6px] lg:pl-[16px] pr-2 sm:text-[10px]'>
                    보건보험의 보장범위를 확장하여 모든사람에게 보건 서비스를 보장하는 개념
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='lg:mt-[90px] mt-10'>
              <div className='font-extrabold flex justify-center lg:text-[55px] text-[12px] sm:text-xl'>핵심 전략</div>
              <div className='flex justify-center lg:mt-[87px] mt-10'>
                <div className='flex justify-center lg:ml-6 lg:mr-6 ml-4 mr-4 sm:ml-14 sm:mr-14'>
                  <div>
                    <img src={Service4} className='lg:mr-[18px]' decoding='async' loading='lazy' />
                    <div className='lg:pt-[38px] pt-2 lg:text-[38px] text-[8px] text-[#0085FF] flex justify-center font-semibold sm:text-[12px]'>
                      하루 1분 관리
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <img
                      src={Plus}
                      className='lg:h-[60px] lg:w-[60px] w-[11px] h-[11px] mr-[18px] sm:w-[45px] sm:h-[30px]'
                      decoding='async'
                      loading='lazy'
                    />
                  </div>
                  <div>
                    <img src={Service5} className='lg:mr-[18px]' decoding='async' loading='lazy' />
                    <div className='lg:pt-[38px] pt-2 lg:text-[38px] text-[8px] text-[#0085FF] flex justify-center text-center font-semibold sm:text-[12px]'>
                      부모, 기관, 자녀
                      <br />
                      GHR 기반
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <img
                      src={Plus}
                      className='lg:h-[60px] lg:w-[60px] w-[11px] h-[11px] mr-[18px] sm:w-[45px] sm:h-[30px]'
                      decoding='async'
                      loading='lazy'
                    />
                  </div>
                  <div>
                    <img src={Service6} className='lg:mr-[18px]' decoding='async' loading='lazy' />
                    <div className='lg:pt-[38px] pt-2 lg:text-[38px] text-[8px] text-[#0085FF] flex justify-center font-semibold sm:text-[12px]'>
                      GHR데이터 생성
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='lg:mt-[90px] flex justify-center lg:mb-[111px] mt-6 mb-10 lg:ml-6 lg:mr-6 sm:ml-10 sm:mr-10'>
              <p className='lg:text-[24px] text-[6px] text-gray-500 sm:text-[14px]'>
                * 빅데이터 활용을 통한 맞춤형 서비스 (예: 맞춤형 식단, 복약, 요양기관, 건강기능식품 등등) - 실버클러스터 형
                빅데이터 - 실버클러스터 형 빅데이터
              </p>
            </div>
          </div>
        </>
      )}
      {isImageLoaded && <Footer />}
    </>
  );
};

export default ServicePage;
