import { authClient, unAuthClient } from "./axiosBase";
import { map } from 'lodash';

interface ICommSelInput {
    gbn: string; // * 조회 구분

    data1: string;
    data2: string;
    data3: string;
    data4: string;
    data5: string;
}

interface ISendMailInput {
    workname: string;
    homepage: string;
    business: string;
    name: string;
    position: string;
    phone: string;
    email: string;
    proposal: string;
    purpose: string;
    files: FileList[];

}

interface ISendImgPathInput {
  files: FileList[];
}


export const fetchCommSel = async ({ gbn, data1, data2, data3, data4, data5 }: ICommSelInput) => await authClient.post('/admin/userList', {
    in_gbn: gbn,
    in_data1: data1,
    in_data2: data2,
    in_data3: data3,
    in_data4: data4,
    in_data5: data5, 
});

export const fetchSendMail = async ({ workname, homepage, business, name, position, phone, email, proposal, purpose, files }: ISendMailInput) => {
    const formData = new FormData();
    formData.append('workname', workname);
    formData.append('homepage', homepage);
    formData.append('business', business);
    formData.append('name', name);
    formData.append('position', position);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('proposal', proposal);
    formData.append('purpose', purpose);
  
    if (files) {
      map(files, (file) => {
        formData.append('files', file[0]);
      });
    }
  
    const { data } = await unAuthClient.post('/admin/sendEmail', formData);
    return data;
};

export const fetchImgPath = async ({ files }: ISendImgPathInput) => {
  const formData = new FormData();

  if (files) {
    map(files, (file) => {
      formData.append('files', file[0]);
    });
  }

  const { data } = await unAuthClient.post('/admin/uploadImg', formData);
  return data;
};