import {useState,useEffect} from 'react';
import { useNavigate, } from 'react-router-dom';

import { fetchAdminCreateUser,fetchAdminUserList } from '../../lib/api/adminApi'
import Modal from '../modal/modalComponent';
import WarnningModal from '../modal/warningComponent';
import { set } from 'lodash';

interface modalProps {

    setIsModalOpen: () => void;
   
}
interface Inputs {
    
username:string;
userId:string;
password:string;
phoneNumber:string;
rank:string;

}

interface ListItem {
	
	USER_ID: string;
	USER_NM: string;
	USER_RANK: string;
	HP_NO: string;


}




const ModalComponent = ({ setIsModalOpen,  } : modalProps)  => {

	let navigate = useNavigate();
	const [ ShowModal, setShowModal] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const [ userList, setUserList ] = useState<ListItem[]>([]);

	const getUserList = async () => {

		
		const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
		// const user_Id: string = (getLoginInfo && JSON.parse(getLoginInfo)?.id);
		

	try {
		
		const inputData= {

				gbn:'SEL', 
				data1:'',
				data2:'',
				data3:'',
				data4:'',
				data5:'',
				

			}

			console.log('save input:'+ JSON.stringify(inputData));
			

			
		const {data} = await fetchAdminUserList(inputData)
	
		console.log('User_modi:'+ JSON.stringify(data))

		if(data.success){
		
		setUserList(data.data);
		localStorage.setItem('setUserList', JSON.stringify(data));
		// window.location.href = "/"

		}else{
		// alert('실패')

		}
	
	} catch (e) {
		// setError(e);
	}

	}

	useEffect(()=>{

	
	getUserList()
    },[])

	const [inputs, setInputs] = useState<Inputs>({

        username:'',
        userId:'',
        password:'',
        phoneNumber:'',
		rank:'',


    })


	const onChangeInfo = (e: React.ChangeEvent<HTMLInputElement>): void => {
  
    
		const { name, value } = e.target   
		
	   
		const nextInputs = {            
			//스프레드 문법으로 기존의 객체를 복사한다.
			...inputs,  
			[name]: value,
		}
	 
		setInputs(nextInputs)  
	 
		
		}
		const handleSave = async () => {

			if(inputs.userId == ''){

				setShowWarning(true)
				setShowModal(false)
				return false;
			}
			if(inputs.password == ''){

				setShowWarning(true)
				setShowModal(false)
				return false;
			}
			if(inputs.username == ''){

				setShowWarning(true)
				setShowModal(false)
				return false;
			}
	

			const getLoginInfo: string | null = localStorage.getItem('junction_login') || '';
	
			const UserId: string = (getLoginInfo && JSON.parse(getLoginInfo)?.USER_ID);
		  
		   
			try {
	
				
				const inputData= {
	
					in_sts: 'I',
					in_user_id:inputs.userId,
					in_user_pw:inputs.password,
					in_user_rank:inputs.rank,
					in_user_nm:inputs.username,
					in_hp_no:inputs.phoneNumber,
					in_login_id:UserId
					
					
				   
	
				}
					console.log('save input:'+ JSON.stringify(inputData));
			   
	
			/* 		return false; */
					const {data} = await fetchAdminCreateUser(inputData)
			
					console.log('onClickLogin end:'+ JSON.stringify(data))
						if(data.success){
						
							setIsModalOpen()
							window.location.href = "/admin-login/user"
							getUserList();

							
	
						}else{
							
							alert('실패')
						}
					
					} catch (e) {
						// setError(e);
				}
		}	
	
	
	





  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="absolute inset-0  bg-gray-500 opacity-75 h-auto  " />
        <div className="z-10 : bg-white rounded-lg shadow-xl p-4 lg:h-auto  font-bold lg:h-[60%] lg:w-[50%] py-10 px-10 ">
            <div className='border-b flex relative pb-2'> 직원등록
            <div className='absolute right-4 text-[20px] cursor-pointer'  onClick={() => setIsModalOpen()}>X</div>
            </div>
           
            <div className='mt-4'>
            <label className="after:content-['*'] after:ml-0.5 after:text-red-500 text-sm text-gray-600 pl-1"> 사용자 이름</label>
                		<div className="mt-2">
							<input
								id="username"
								name="username"
								type="text"
								placeholder='이름'
                                onChange={onChangeInfo}
								className="w-full  border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600 "
							/>
                		</div>
						
            </div>
            <div>
                		<div className="mt-4">
							<label className="after:content-['*'] after:ml-0.5 after:text-red-500 text-sm text-gray-600 pl-1">아이디</label>
							<input
								id="userId"
								name="userId"
								type="text"
								placeholder='아이디'
                                onChange={onChangeInfo}
							
								className="w-full  border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600 "
							/>
                		</div>
						
              		</div>
                      <div>
						<div className="mt-4">
							<label className="text-sm text-gray-600 pl-1 after:content-['*'] after:ml-0.5 after:text-red-500">비밀번호</label>
							<input
								id="password"
								name="password"
								type="password"
								placeholder='비밀번호'
					
                                onChange={onChangeInfo}
								
						
								className="w-full  border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600   "
							/>
                		</div>
				
             		</div>
                     <div className="mt-4">
					 	<label className='text-sm text-gray-600 pl-1'>휴대폰번호</label>
							<input
								id="phoneNumber"
								name="phoneNumber"
								type="text"
								placeholder='휴대폰번호'
								
                                onChange={onChangeInfo}
						
								className="w-full border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600  "
							/>
                		</div>
						<div className="mt-4">
					 	<label className='text-sm text-gray-600 pl-1'>직급</label>
							<input
								id="rank"
								name="rank"
								type="text"
								placeholder='직급'
								
                                onChange={onChangeInfo}
						
								className="w-full border h-[40px] pl-4 rounded-md border-[#0085FF] placeholder:text-gray-400  placeholder:text-sm  text-gray-600 text-sm focus:outline-none focus:outline-none focus:ring-2 focus:ring-blue-600  "
							/>
                		</div>
				
				
              	
            <div className='flex justify-center mt-10    mb-2'> 
                <button className='bg-[#2999ff] text-[18px] text-white w-[180px] h-[40px] rounded-lg font-bold cursor-pointer hover:bg-blue-600' onClick={()=>{setShowModal(true)}}>등록하기</button>
             
            </div>
			{ShowModal &&(
				<Modal
				setIsModalOpen={() => setShowModal(false)}
				header={'신규등록'}
				sendMessage={'등록 하시겠습니까?.'}
				storageMessage={'Yes버튼을 누르면 등록됩니다.'}
				handleSave={() => handleSave()}
				/>
			)}
			  {showWarning && (
				<WarnningModal
					header='필수값 확인 '
					sendMessage='필수항목을 입력하지 않았습니다.'
					storageMessage='필수 입력항목을 확인해주세요.'
					setIsModalOpen={() => setShowWarning(false)}
				/>
			)}
</div>
</div>
  )
}

export default ModalComponent;