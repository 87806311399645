import { authClient, unAuthClient } from "./axiosBase";

// interface IAdminUserListInput {
//     gbn: string;
//     userId: string;
//     keyword: string;
// }

interface IAdminCreateUserList {
    in_sts: string;
    in_user_id: string;
    in_user_rank: string;
    in_user_nm: string;
    in_hp_no:string;
    in_login_id: string;
    in_user_pw:string;
    
}

interface IAdminModifyUserList {
    in_sts: string;
    in_user_id: string;
    in_user_rank: string;
    in_user_nm: string;
    in_user_pw: string;
    in_hp_no: string;
    in_login_id: string;
 
}

interface IAdminBoardListInput {
    in_gbn: string;
    in_seq: string | number;
    in_board_gbn: string;
    in_keyword: string;    
}

interface IAdminBoardModifyInput {
    in_sts: string;
    in_seq: string | number;
    in_board_gbn: string;
    in_write_dt: string;
    in_write_seq: string;
    in_notice_yn: string;
    in_title_img_path: string;
    in_title: string;
    in_contents: string;
    in_start_dt: string;
    in_end_dt: string;
    in_id: string;

}



interface ICommSelInput {
    gbn: string; // * 조회 구분

    data1: string;
    data2: string;
    data3: string;
    data4: string;
    data5: string;
}
/*************** admin user start ***************/
// 유저 조회 (관리자) 
export const fetchAdminUserList = async ({ gbn, data1, data2, data3, data4, data5 }: ICommSelInput) => await authClient.post('/admin/userList', {
    in_gbn: gbn,
    in_data1: data1,
    in_data2: data2,
    in_data3: data3,
    in_data4: data4,
    in_data5: data5, 
});
// 생성 (관리자)
export const fetchAdminCreateUser = async ({ in_user_id,in_user_rank,in_user_pw,in_user_nm,in_hp_no ,in_login_id }: IAdminCreateUserList) => await authClient.post('/admin/userModify', {
    in_sts: 'I',
    in_user_id: in_user_id,
    in_user_rank: in_user_rank,
    in_user_pw: in_user_pw,    
    in_user_nm: in_user_nm,    
    in_hp_no: in_hp_no,  
    in_login_id: in_login_id,
});  
// 회원 수정(관리자)
export const fetchAdminModifyUser = async ({ in_sts,in_user_id, in_user_rank,in_user_nm,in_user_pw,in_hp_no, in_login_id }: IAdminModifyUserList) => await authClient.post('/admin/userModify', {
    in_sts: in_sts,
    in_user_id: in_user_id,       
    in_user_rank: in_user_rank, 
    in_user_nm: in_user_nm,
    in_user_pw: in_user_pw,
    in_hp_no: in_hp_no,
    in_login_id: in_login_id,

});   
/*************** admin user end ***************/

/*************** admin boardList start ***************/
// 게시글 조회 (관리자)
export const fetchAdminBoardList = async ({ in_gbn, in_seq, in_board_gbn,in_keyword }: IAdminBoardListInput) => await authClient.post('/admin/boardList', {
    in_gbn: in_gbn,
    in_seq: in_seq,
    in_board_gbn: in_board_gbn,
    in_keyword:in_keyword
});
// 게시글 수정 (관리자)
export const fetchAdminBoardModify = async ({ in_sts,in_seq,in_board_gbn,in_write_dt,in_write_seq,in_notice_yn,in_title_img_path,in_title,in_contents,in_start_dt,in_end_dt,in_id}: IAdminBoardModifyInput) => await authClient.post('/admin/boardModify', {
    in_sts: in_sts,
    in_seq: in_seq,
    in_board_gbn: in_board_gbn,
    in_write_dt:in_write_dt,
    in_write_seq:in_write_seq,
    in_notice_yn:in_notice_yn,
    in_title_img_path:in_title_img_path,
    in_title:in_title,
    in_contents:in_contents,
    in_start_dt:in_start_dt,
    in_end_dt:in_end_dt,
    in_id:in_id,

});



/*************** admin reservation end ***************/


export const fetchUserBoardList = async ({ in_gbn, in_seq, in_board_gbn,in_keyword }: IAdminBoardListInput) => await unAuthClient.post('/boardList', {
    in_gbn: in_gbn,
    in_seq: in_seq,
    in_board_gbn: in_board_gbn,
    in_keyword:in_keyword
});